<script setup lang="ts">
import type { TicketDetailAttendance } from '@/types/profile';

interface Ticket {
	id: number | null;
	name: string;
	cancelledQty: number;
	restQty: number;
	status: string;
	seats: string;
	price: number;
}

const props = defineProps({
	data: {
		type: Array as PropType<Array<TicketDetailAttendance>>,
		default: () => [],
		required: true,
	},
});

const orderTickets = computed(() => {
	if (!props.data) {
		return [];
	}
	const tickets: Ticket[] = [];
	const defaultAttendanceObj: Ticket = {
		id: null,
		name: '',
		cancelledQty: 0,
		restQty: 0,
		status: '',
		seats: '',
		price: 0,
	};

	const attendances = props.data;
	for (const attendance of attendances) {
		let ticket = tickets.find((item) => item.name === attendance.ticket.name);

		if (!ticket) {
			// If ticket doesn't exist, create a new one
			ticket = { ...defaultAttendanceObj };
			ticket.id = attendance.id;
			ticket.name = attendance.ticket.name;
			ticket.status = attendance.status;
			ticket.seats = attendance.seats || '';
			tickets.push(ticket);
		}

		if (attendance.status === ATTENDEE_STATUS.CANCELED) {
			ticket.cancelledQty += attendance.quantity;
		} else {
			ticket.restQty += attendance.quantity;
		}
	}

	return tickets;
});

const totalCancelled = computed(() => {
	return orderTickets.value?.reduce((total, curr) => total + curr.cancelledQty, 0);
});
</script>

<template>
  <div class="ticket-detail-container text-left ">
    <div
      v-for="(ticket, ticketIndex) in orderTickets"
      :key="ticketIndex"
      class="ticket-detail-wrapper"
    >
      <p class="ticket text-sm text-regular no-wrap">
        <span>{{ ticket?.restQty }}x</span>
        <span>{{ " " }}</span>
        <span
          v-if="ticket?.cancelledQty"
          class="cancelled-qty"
        >
          {{ ticket.cancelledQty }}x</span>
        <span v-if="ticket?.cancelledQty">{{ " " }}</span>
        <span>{{ ticket?.name }}</span>
      </p>
      <p
        v-if="ticket?.seats"
        class="ticket-seat text-sm text-regular"
      >
        {{ ticket.seats }}
      </p>
    </div>
    <p
      v-if="totalCancelled"
      class="total text-xs text-regular"
    >
      {{ totalCancelled }} ticket(s) have been canceled.
    </p>
  </div>
</template>

<style scoped lang="scss">
.ticket-detail-container {
  display: flex;
  flex-direction: column;
  row-gap: spacings-get(2);

	.ticket-detail-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: spacings-get(2);
	}

  .ticket {
    display: flex;
    column-gap: rem(2);
    color: colors-get(gray, 700);

    .cancelled-qty {
      text-decoration: line-through
    }
  }

	.ticket-seat {
		text-align: right;
	}

  .total {
    color: colors-get(gray, 400);
  }
}
</style>
