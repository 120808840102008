import type { Ticket } from '@/types/ticket';
import type { ErrorObject } from '@vuelidate/core';
import dayjs from 'dayjs';

export interface ErrorCollection {
	[key: string]: ErrorObject[]
}

export const getValidationErrorMessage = (errors: ErrorObject[]) => {
	return errors?.map((e) => e.$message).join('\r\n');
};

export const getValidationErrorMessageWithPropName = (errors: ErrorObject[], propName: string) => {
	const errorMessages = errors?.find((e) => e.$property === propName);
	if (!errorMessages) {
		return null;
	}
	return errorMessages.$message;
};

export const getValidationErrorMessageCollection = (errors: ErrorCollection[], index: number, propName: string) => {
	const errorMessages = errors?.[index]?.[propName];
	return getValidationErrorMessage(errorMessages);
};

export const getChildErrorMessageFromParentCollection = (errors: ErrorObject[], childIndex: number) => {
	const errorMessages = errors?.map((e) => {
		// `messages` is Array of string array e.g.
		// [
		// 	['Error message'],
		// 	['Error message']
		// ]
		const messages = e.$message;
		const message = (messages as any)[childIndex];
		return message;
	});

	if (!errorMessages.length) {
		return '';
	}
	return errorMessages[0][0];
};

export const isValidTime = (time: string) => {
	const timeObj = dayjs(time, 'hh:mm A', true);
	return timeObj.isValid();
};

export const isValidQuantity = (value: string) => {
	// Check if the value is numeric and greater than 0
	return /^[1-9]\d*$/.test(value);
};

export const isValidFreeTicketName = (currentName: string, currentId: number | null, existItems: Ticket[]) => {
	if (!currentName) {
		return true;
	}

	const isDuplicateTicketName = existItems.some((item) => {
		return item.price === 0 &&
			currentId !== item.id &&
			currentName.toLocaleLowerCase() === item.name.toLocaleLowerCase();
	});

	return !isDuplicateTicketName;
};

export const isValidPaidTicketName = (currentName: string, currentId: number | null, existItems: Ticket[]) => {
	if (!currentName) {
		return true;
	}

	const isDuplicateTicketName = existItems.some((item) => {
		return currentId !== item.id &&
			currentName.toLocaleLowerCase() === item.name.toLocaleLowerCase();
	});

	return !isDuplicateTicketName;
};

export const isValidItemName = <T>(currentName: string, existItems: T[], propsName: string = 'name') => {
	if (!currentName) {
		return true;
	}

	const duplicatedItems = existItems.filter((item) => {
		if (item instanceof Object) {
			const itemName = (item as any)[propsName];
			return currentName.toLowerCase() === itemName.toLowerCase();
		}
		return false;
	});

	// If there is one or less occurrence of "currentName" in "existItems"
	// return true. Otherwise, return false.
	return duplicatedItems.length <= 1;
};

export const isValidPhoneNumber = (value: string) => {
	// Check if the value is match phone number pattern
	// E.g.
	// (123)456-7890
	// +(123)456-7890
	// +(123)-456-7890
	// +(123)-456-7890
	// +(123)-456-78-90
	// 123-456-7890
	// 1234567890
	// +31636363634
	// 075-63546725
	if (!value) {
		return true;
	}
	return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-0-9]*$/.test(value);
};

export const isValidEmail = (email: string): boolean => {
	// A simple email regex. Adjust as needed.
	const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return regex.test(email);
};