<script setup lang="ts">
const props = defineProps({
	label: {
		type: String,
		default: undefined,
	},
	name: {
		type: String,
		default: '',
	},
	value: {
		type: String,
		default: '',
		required: true,
	},
	id: {
		type: String,
		default: '',
		required: true,
	},
	modelValue: {
		type: [Boolean, String, Array as () => Array<string>],
		default: '',
		required: true,
	},
	modelModifiers: {
		type: Object,
		default: () => ({}),
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits<{
	(e: 'update:modelValue', value: string | boolean | string[] | null): void
	(e: 'onChange', value: boolean): void
}>();

const slots = useSlots();

function handleInput(event: Event) {
	const targetElement = event.target as HTMLInputElement;
	const isCheckedValue: boolean = targetElement.checked;

	if (props.modelValue instanceof Array) {
		const newValue = isCheckedValue
			? [...props.modelValue, targetElement.value]
			: props.modelValue.filter((v: string) => v !== props.value);
		emit('update:modelValue', newValue);
	} else if (props.modelModifiers.multipleStringWithComma && props.value) {
		// Support multiple selection as string with comma separate
		let newValue = props.value;
		// Check pre-selected values.
		// if props.modelValue is 'string', there's pre-selected values
		// if props.modelValue is 'boolean', there's no pre-selected values
		if (typeof props.modelValue === 'string') {
			const arrayValue = props.modelValue.split(',');
			// If 'checked' push the new value to array, otherwise filter the value out from array.
			if (isCheckedValue) {
				arrayValue.push(props.value);
				newValue = arrayValue.join();
			} else {
				const filteredArray = arrayValue.filter((v: string) => v !== props.value);
				newValue = filteredArray.join();
			}
		}
		emit('update:modelValue', newValue);
	} else {
		emit('update:modelValue', isCheckedValue);
	}
}

const isChecked = computed(() => {
	// Handle multiple selection case, the 'modelValue' could be either Array or String with comma separate
	// E.g. ["A", "B"] or "A,B"
	if (props.modelValue instanceof Array ||
      (props.modelModifiers.multipleStringWithComma && typeof props.modelValue === 'string' && props.value)
	) {
		return props.modelValue.includes(props.value);
	}

	// Handle case that checkbox is boolean
	if (typeof props.modelValue === 'boolean' && !props.value) {
		return props.modelValue;
	}

	return props.modelValue === props.value;
});

const hasHelperSlot = computed(() => {
	return !!slots.helperText;
});

const hasLabelSlot = computed(() => {
	return !!slots.labelText;
});

watch(() => isChecked.value, (newValue) => {
	emit('onChange', newValue);
});
</script>

<template>
  <label
    :for="id"
    class="checkbox-container"
  >
    <input
      :id="id"
      :checked="isChecked"
      :value="value"
      :name="name"
      :disabled="disabled"
      class="checkbox"
      type="checkbox"
      @input="handleInput"
    >
    <span :class="['checkmark', { top: hasHelperSlot }]" />
    <span
      :class="['label', 'text-sm', { 'text-medium': hasHelperSlot }]"
    >
      <template v-if="hasLabelSlot">
        <slot name="labelText" />
      </template>
      <template v-else>
        <span>{{ label }}</span>
      </template>
    </span>
    <p
      v-if="hasHelperSlot"
      class="helper-text"
    >
      <slot name="helperText" />
    </p>
  </label>
</template>

<style lang="scss" scoped>
.checkbox-container {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  padding-left: rem(20);
  margin: 0;
  @include fonts-get(regular, text-sm);
  color: colors-get(gray, 600);

  .label {
		margin-bottom: 0;

		&.text-medium {
			color: colors-get(gray, 700);
		}
	}

  &:hover > .checkmark {
    background: colors-get(primary, 100);
    border: solid 1px colors-get(primary, 600);
  }

  .checkbox {
    position: absolute;
    left: 0;
    opacity: 0;
    height: 0;
    width: 0;

    &:focus ~ .checkmark {
      box-shadow: 0px 0px 0px 4px #f4ebff;
    }

    &:checked ~ .checkmark {
      background: colors-get(primary, 100);
      border: solid 1px colors-get(primary, 600);
    }

    &:checked ~ .checkmark:after {
      display: block;
    }

    &:disabled {
      & ~ .checkmark {
        background: colors-get(gray, 100);
        border: solid 1px colors-get(gray, 300);
        cursor: not-allowed;
      }

      &:checked ~ .checkmark:after {
        background-image: url('/src/assets/svg/inactive-checkbox.svg');
      }
    }
  }

  .checkmark {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    height: rem(14);
    width: rem(14);
    background-color: colors-get(base, white);
    border-radius: 4px;
    border: 1px solid colors-get(gray, 300);
    cursor: pointer;

    &.top {
      top: 0;
      transform: translate(0, 25%);
    }

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: rem(12);
      height: rem(12);
      background-image: url('/src/assets/svg/checkbox.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .helper-text {
    margin-top: 0;
  }
}
</style>