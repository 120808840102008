export enum SEARCH_QUERY_NAME {
	KEYWORD = 'k',
	COORDINATES = 'o',
	LOCATION = 'l',
	COUNTRY_CODE = 'c',
	TIME_STAMP = 't',
	DATE_START = 'ds',
	DATE_END = 'de',
	EVENT_TYPE = 'e',
}

export enum AUTH_QUERY_NAME {
	SIGNIN_TYPE = 'signin_type',
}

export enum CONNECT_STRIPE_STATUS_QUERY_NAME {
	CONNECT_STATUS = 'connect-status',
}

export enum INVITATION_QUERY_NAME {
	INVITATION = 'invitation',
	EVENT = 'event',
	ATTENDANCE = 'attendance',
	TOKEN = 'token',
}

export enum UNSUBSCRIBE_QUERY_NAME {
	EMAIL = 'email',
	TOKEN = 'token',
	ORGANIZER_ID = 'organizer_id',
}