import currency from 'currency.js';
import numeral from 'numeral';
import type { CURRENCY_CODE as CURRENCY_CODE_ENUM } from '@/enums/common';

export const processInteger = (value: string, defaultValue: string | number | null) => {
	let processedValue: string | number | null = value.replace(/[^0-9.]/g, '');

	if (processedValue.startsWith('0')) {
		processedValue = processedValue.substring(1);
	}

	if (processedValue !== '') {
		const parsedNumber = Number(processedValue);
		processedValue = !isNaN(parsedNumber) ? parsedNumber : defaultValue;
	}

	return processedValue;
};

export const processFloat = (value: string, isAllowZero: boolean = false) => {
	let processedValue: string = value
		.replace(/[^0-9.]/g, '') // Keep only digits and dot (.)
		.replace(/^(\d+\.\d{2}).*$/, '$1'); // Allow only two decimal places

	if (isAllowZero) {
		return processedValue;
	}

	// Ensure the first digit is not 0 unless it's "0."
	if ((processedValue.startsWith('0') || processedValue.startsWith('.')) && processedValue !== '0.') {
		processedValue = processedValue.substring(1);
	}

	return processedValue;
};

export const formatNumeral = (value: string | number, format: string = COMMA) => {
	return numeral(Number(value)).format(format);
};

export const formatCurrency = (
	inputValue: number | string,
	code?: CURRENCY_CODE_ENUM | string,
	hasSeparator = true,
) => {
	const USD = (value: number | string) => currency(value);
	const JPY = (value: number | string) => currency(value, {
		precision: 0,
		symbol: mappedCurrencySymbol[CURRENCY_CODE.JPY],
	});
	const EURO = (value: number | string) => currency(value, {
		symbol: mappedCurrencySymbol[CURRENCY_CODE.EUR],
	});
	const OTHER = (value: number | string) => currency(value, {
		...((code && mappedCurrencySymbol[code]) ? { symbol: mappedCurrencySymbol[code] } : { symbol: '' }),
		...(hasSeparator ? {} : { separator: '' }),
	});

	switch (code) {
		case CURRENCY_CODE.USD:
			return USD(inputValue).format();
		case CURRENCY_CODE.JPY:
			return JPY(inputValue).format();
		case CURRENCY_CODE.EUR:
			return EURO(inputValue).format();
		default:
			return OTHER(inputValue).format();
	}
};

export const toCents = (value: number) => {
	if (typeof value !== 'number') {
		return 0;
	}
	return value * 100;
};

export const formatByteSize = (sizeInBytes: number) => {
	const units = ['B', 'KB', 'MB', 'GB', 'TB'];

	let unitIndex = 0;
	let size = sizeInBytes;

	while (size >= 1024 && unitIndex < units.length - 1) {
		size /= 1024;
		unitIndex++;
	}

	return `${Math.round(size)} ${units[unitIndex]}`;
};

export const ensureNumeric = (value: string | number) => {
	if (typeof value === 'string') {
		const newValue = parseFloat(value);
		return isNaN(newValue) ? '' : newValue;
	}

	return value;
};