import type { Contact, InvitedEvent } from '@/types/contact';
import type { EventInfo } from '@/types/event';

export const transformGoogleContacts = (googleContacts: gapi.client.people.Person[], existingContacts: Contact[] = []) => {
	if (!googleContacts?.length) {
		return [];
	}

	// Create lookup map for existing contacts for O(1) access
	const existingContactsMap = new Map(existingContacts.map((contact) => [contact.email, contact]));

	// Transform contacts in a single pass without intermediate array
	const modifiedContacts: Contact[] = [];

	for (const contact of googleContacts) {
		const email = contact.emailAddresses?.[0]?.value;

		// Only process contacts with email that don't exist yet
		if (email && !existingContactsMap.has(email)) {
			modifiedContacts.push({
				name: contact.names?.[0]?.displayName || '-',
				email,
				status: CONTACT_STATUS.UNINVITED,
			});
		}
	}

	return modifiedContacts;
};

export const transformInvitedEventDetail = (event: InvitedEvent): EventInfo => {
	const timezone = event.start.timezone;
	const startDateTime = event.start.utc;
	const displayDate = formatEventDisplayDate(startDateTime, timezone);
	const displayTime = formatEventDisplayTime(startDateTime, timezone);

	return {
		locationType: '',
		id: event.id,
		groupId: event.group.id,
		groupName: event.group.name,
		groupLogo: event.group.logo,
		status: '',
		coverImageUrl: event.coverImage,
		name: event.name,
		address: '',
		displayDate,
		displayTime,
		displayTimezone: getTimezoneAbbreviation(timezone),
		isHappeningNow: calculateHappeningNow(event.start, event.end),
		avatarUrl: event.organizer.avatar,
		organizer: event.organizer.nickname,
		organizerId: event.organizer.id,
	};
};