export enum STRIPE_CONNECT_ERROR_MESSAGE {
	MISSING_CONNECT_ACCOUNT = 'missing_connect_account',
	HAS_ACTIVE_EVENTS = 'has_active_events',
}

export enum EVENT_ERROR_MESSAGE {
	FAILED = 'reCAPTCHA verification failed. You might be a bot.',
}

export enum COMMON_ERROR_MESSAGE {
	TRY_AGAIN = 'Something went wrong. Please try again.',
	REFRESH_PAGE = 'Something went wrong. Please try refresh the page.',
}

export enum CLAIM_TICKET_ERROR_MESSAGE {
	CANNOT_CLAIM = 'cannot_claim_ticket',
	NOT_FOUND = 'not_found',
	INVALID_USER_EMAIL = 'invalid_user_email',
	INVALID_TOKEN = 'invalid_token',
	INVALID_EVENT = 'invalid_event',
	ALREADY_ACCEPTED = 'invitation_already_accepted',
}