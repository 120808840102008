<script setup lang="ts">
import type { PaymentCard } from '@/types/common';

const profileStore = useProfileStore();
const notificationStore = useNotificationStore();
const { isMobile } = useWindowResize();

const currentPage = ref(profileStore.card.data?.paginationInfo.currentPage ?? 1);
const showDeleteDlg = ref(false);
const deletedCardId = ref();
const isOpenAddCardSideBar = ref(false);
const isAddingCard = ref(false);
const currentTotalCard = ref();
const intervalId = ref<ReturnType<typeof setTimeout>>();

const cardData = computed(() => profileStore.cardList || []);
const paginationInfo = computed(() => profileStore.card.data?.paginationInfo);
const isLoading = computed(() => profileStore.card.isLoading);

async function handleUpdate() {
	if (profileStore.profile?.id) {
		await profileStore.getCardList(profileStore.profile.id, {
			currentPage: currentPage.value,
		});
	}
}

async function handlePolling(successMessage: string, interval: number = 2000) {
	intervalId.value = setInterval(async () => {
		await handleUpdate();

		// Keep polling until there is a difference between the current and the previous number
		// Otherwise, continue polling every interval
		if (currentTotalCard.value !== paginationInfo.value?.totalItems) {
			clearInterval(intervalId.value);
			notificationStore.showSuccessNotification(successMessage);
			isAddingCard.value = false;
		}
	}, interval);
}

function handlePageChange(page: number) {
	currentPage.value = page;
	handleUpdate();
}

function handleAddNewCard() {
	isOpenAddCardSideBar.value = true;
}

function handleSidebarClose() {
	isOpenAddCardSideBar.value = false;
}

function handleAddCreditCardFail() {
	isOpenAddCardSideBar.value = false;
	notificationStore.showErrorNotification(COMMON_ERROR_MESSAGE.TRY_AGAIN);
}

async function handleAddCreditCardSuccess() {
	isAddingCard.value = true;
	isOpenAddCardSideBar.value = false;
	// Store the current number of cards
	currentTotalCard.value = paginationInfo.value?.totalItems;

	await handlePolling('Successfully added a new credit/debit card.');
}

function handleClickDeleteBtn(cardInfo: PaymentCard) {
	deletedCardId.value = cardInfo.stripePaymentMethodId;
	showDeleteDlg.value = true;
}

async function handleDeleteCard() {
	if (profileStore.profile?.id) {
		await profileStore.deleteCard(profileStore.profile.id, deletedCardId.value);
	}

	if (profileStore.card.errorMessage) {
		notificationStore.showErrorNotification(COMMON_ERROR_MESSAGE.TRY_AGAIN);
	} else {
		currentPage.value = 1;
		await handleUpdate();

		notificationStore.showSuccessNotification('Successfully deleted a credit/debit card.');
		// Reset current total card to the latest value
		currentTotalCard.value = paginationInfo.value?.totalItems;
	}
	deletedCardId.value = null;
	showDeleteDlg.value = false;
}

onMounted(async () => {
	// Reset the current page
	currentPage.value = 1;
	handleUpdate();
});
</script>

<template>
  <div class="saved-card-container">
    <div class="add-card-container">
      <h3 class="add-card-header text-sm text-semibold">
        Credit/debit card
      </h3>

      <ButtonSkeletonLoader
        v-if="isLoading || isAddingCard"
        width="172"
        height="46"
      />
      <BaseButton
        v-else
        class="add-btn"
        variant="solid"
        color="primary"
        size="lg"
        @click="handleAddNewCard"
      >
        <CreditCardPlusIcon
          width="20"
          height="20"
        />
        Add new card
      </BaseButton>
    </div>

    <template v-if="isLoading || isAddingCard">
      <MultipleSkeletonLoader
        class="card-loaders"
        rows="5"
      >
        <TextInputSkeletonLoader
          :loading="isLoading"
          :width="isMobile ? `auto` : 464"
          height="66"
        />
      </MultipleSkeletonLoader>
    </template>
    <VDataTable
      v-else
      class="saved-card-table"
      density="compact"
      :show-current-page="false"
      :items="cardData || []"
    >
      <template #headers />
      <template #no-data>
        <template v-if="isLoading">
          <MultipleSkeletonLoader
            class="card-loaders"
            rows="5"
          >
            <TextInputSkeletonLoader
              :loading="isLoading"
              width="464"
              height="66"
            />
          </MultipleSkeletonLoader>
        </template>
        <template v-else>
          <div class="table-no-data">
            <SearchIcon
              class="icon"
              width="24"
              height="24"
            />
            <p class="header text-md text-semibold">
              No saved cards found
            </p>
            <p class="text-sm text-regular">
              Your saved cards will be listed here after you add new ones.
            </p>
          </div>
        </template>
      </template>
      <template #item="{ item }">
        <div class="saved-card-option">
          <component
            :is="getCreditCardIcon(item?.brand)"
            class="icon-payment credit-card"
          />
          <CreditCardInfo :card="item" />
          <BaseButton
            class="trash-btn"
            variant="outlined"
            color="gray"
            size="sm"
            icon-only
            @click="handleClickDeleteBtn(item)"
          >
            <TrashIcon />
          </BaseButton>
        </div>
      </template>
      <template #bottom>
        <BasePagination
          v-if="cardData?.length"
          :page="currentPage"
          :total-pages="paginationInfo?.totalPages"
          :is-loading="isLoading"
          @on-page-change="handlePageChange"
        />
      </template>
    </VDataTable>
  </div>
  <ConfirmDialog
    :is-show="showDeleteDlg"
    :is-loading="isLoading"
    confirm-button-name="Delete"
    confirm-button-color="error"
    @on-close="showDeleteDlg = false"
    @on-confirm="handleDeleteCard"
  >
    <template #header>
      <div class="icon-header-confirm-dlg icon-error">
        <TrashIcon
          width="24"
          height="24"
          :color="ICON_COLOR.ERROR"
        />
      </div>
    </template>
    <template #title>
      Delete card
    </template>
    <template #content>
      <p class="text-sm text-regular">
        Are you sure you want to delete this card? This cannot be undone.
      </p>
    </template>
  </ConfirmDialog>
  <AddCreditCardSideDrawer
    :is-open="isOpenAddCardSideBar"
    :is-loading="isAddingCard"
    @on-fail="handleAddCreditCardFail"
    @on-success="handleAddCreditCardSuccess"
    @on-close="handleSidebarClose"
  />
</template>

<style lang="scss" scoped>
.saved-card-container {
  display: flex;
  flex-direction: column;
  row-gap: spacings-get(8);

  .card-loaders {
    margin-bottom: spacings-get(5);
  }

  .add-card-container {
    display: flex;
    flex-direction: column;
    row-gap: spacings-get(4);

    .add-card-header {
      color: colors-get(gray, 700);
    }

    .add-btn {
      width: fit-content;
    }

    @include media-query-max(mobile) {
      .add-btn {
        width: 100%;
      }
    }
  }

  .saved-card-table {
    .saved-card-option {
      max-width: rem(464);
      display: grid;
      grid-template-columns: min-content auto min-content;
      grid-template-rows: 1fr;
      column-gap: spacings-get(3);
      padding: spacings-get(3) spacings-get(4);
      margin-bottom: spacings-get(4);
      @include border-radius-lg;
      border: 1px solid colors-get(gray, 200);
    }

    :deep(.v-table__wrapper) {
      border-bottom: none;
    }
  }
}
</style>