<script setup lang="ts">
import type { EventInfo } from '@/types/event';

defineProps({
	data: {
		type: Object as PropType<EventInfo>,
		required: true,
	},
	isHostingUserProfile: {
		type: Boolean,
		required: false,
	},
	hideDivider: {
		type: Boolean,
		default: false,
	},
});
const slots = useSlots();

const hasActionSlot = computed(() => {
	return !!slots.action;
});
</script>

<template>
  <div class="event-detail-item">
    <div class="left">
      <div class="cover-image">
        <RouterLink
          :to="{
            name: isHostingUserProfile ? ROUTE.EVENT_EDIT.NAME : ROUTE.EVENT_VIEW.NAME,
            params: {
              id: data.id
            }
          }"
          target="_blank"
        >
          <img
            v-if="data.coverImageUrl"
            :src="data.coverImageUrl"
          >
          <div
            v-else
            class="placeholder"
          >
            <PeatixGrayIcon />
          </div>
        </RouterLink>
      </div>
      <div class="detail">
        <p
          v-if="data.displayDate && data.displayTime"
          class="sub-title"
        >
          <span>{{ `${data.displayDate} ` }}</span>
          <span>&middot;&nbsp;</span>
          <span>{{ `${data.displayTime} ` }}</span>
          <span>{{ data.displayTimezone }}</span>
        </p>
        <RouterLink
          class="title text-wrap"
          :to="{
            name: isHostingUserProfile ? ROUTE.EVENT_EDIT.NAME : ROUTE.EVENT_VIEW.NAME,
            params: {
              id: data.id
            }
          }"
          target="_blank"
        >
          {{ data.name }}
        </RouterLink>
        <slot name="moreDetail" />
        <p class="sub-title">
          {{ data.locationType === EVENT_TYPE.PHYSICAL ? data.address : 'Online event' }}
        </p>
        <slot name="eventStatus" />
      </div>
    </div>
    <div
      v-if="hasActionSlot"
      class="right"
    >
      <slot name="action" />
    </div>
  </div>
  <Divider v-if="!hideDivider" />
</template>

<style lang="scss" scoped>
.event-detail-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: spacings-get(4);
  padding: spacings-get(6) 0;

  @include media-query-max(mobile) {
    flex-direction: column;
    align-items: stretch;
  }

  .left {
    display: flex;
    gap: spacings-get(4);

    @include media-query-max(mobile) {
      flex-direction: column;
    }

    .cover-image {
      width: rem(160);
      min-width: rem(160);
      height: rem(80);

      @include media-query-max(mobile) {
        min-width: none;
        width: 100%;
        height: rem(172);
      }

      .placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: colors-get(gray, 200);
      }

      img {
        width: 100%;
      }

      img,
      .placeholder {
        border-radius: rem(4);
      }

      .placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: colors-get(gray, 200);
        border-radius: rem(4);
      }

      @include media-query-max(mobile) {
        img,
        .placeholder {
            height: 100%;
            @include border-radius-default;
        }
      }
    }

    .detail {
      display: flex;
      flex-direction: column;
      gap: spacings-get(1);

      :deep(.base-chip) {
        height: auto;
      }

      .title {
        text-decoration: initial;
        color: colors-get(gray, 900);
        @include fonts-get(medium, text-sm);
      }

      .sub-title {
        color: colors-get(gray, 500);
        @include fonts-get(regular, text-xs);
      }
    }
  }
}
</style>
