<script setup lang="ts">
import type { ExtendedLocationDetail } from '@/types/location';

const props = defineProps({
	disabled: {
		type: Boolean,
		default: false,
	},
	placeholder: {
		type: String,
		default: '',
	},
	errorMessage: {
		type: String,
		default: undefined,
	},
	modelValue: {
		type: String,
		default: '',
	},
	options: {
		type: Object as PropType<google.maps.places.AutocompleteOptions | null>,
		default: null,
	},
	isSearchBar: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits<{
	(e: 'update:modelValue', value: string): void
	(e: 'onSelectLocation', data: ExtendedLocationDetail): void
	(e: 'onInputChange'): void
	(e: 'focus'): void
	(e: 'blur'): void
	(e: 'enter'): void
}>();

const { autocomplete, initGeocoder, initAutocomplete, fetchLocation } = useGoogleMaps();

const input = ref();
const activator = ref();
const tooltipContent = ref();

const textInput = computed({
	get: () => props.modelValue,
	set: (value) => emit('update:modelValue', value),
});

const isShowTooltip = computed(() => {
	return !!activator.value;
});

async function initialize() {
	if (!autocomplete.value) {
		return;
	}

	// Handle event listener when user select location
	// This event will be fired when user selected location from suggestion list or press Enter
	autocomplete.value.addListener('place_changed', async () => {
		if (!autocomplete.value) {
			return;
		}
		const places = autocomplete.value.getPlace();
		// Emit event only when user selected location
		if (places && places.formatted_address) {
			const { countryCode } = await fetchLocation(places.formatted_address);

			const location: ExtendedLocationDetail = {
				name: places?.name || '',
				address: places.formatted_address || '',
				coordinate: {
					lat: places.geometry?.location?.lat() || null,
					lng: places.geometry?.location?.lng() || null,
				},
				countryCode: countryCode || '',
			};
			emit('onSelectLocation', location);
		}
	});
}

function handleOnFocus() {
	const el = document.querySelector('.pac-container');
	if (!el) {
		return;
	}

	if (el && props.isSearchBar) {
		el.classList.add('-search-bar');
	}

	// Handle to show tooltip when mouse hover on the location suggestion list.
	el?.addEventListener('mouseover', (e) => {
		const target = e.target;

		if (!(target instanceof HTMLElement)) {
			return;
		}

		if (target.className === 'pac-item') {
			activator.value = target;
			tooltipContent.value = target.innerHTML;
		} else {
			const parent = target.closest('.pac-item');
			if (!(parent instanceof HTMLElement)) {
				return;
			}

			activator.value = parent;
			tooltipContent.value = parent.innerHTML;
		}
	});

	emit('focus');
}

function handleOnBlur() {
	// Reset tooltip when user selected location
	activator.value = null;
	tooltipContent.value = null;

	emit('blur');
}

function handlePressEnter(event: Event) {
	event.preventDefault();

	emit('enter');
}

onMounted(async () => {
	if (window.google && window.google.maps) {
		await initGeocoder();
		await initAutocomplete(input.value.input, props.options);
	} else {
		window.addEventListener('load', async () => {
			if (window.google && window.google.maps) {
				await initGeocoder();
				await initAutocomplete(input.value.input, props.options);
			}
		});
	}

	initialize();
});
</script>

<template>
  <BaseTextInput
    ref="input"
    v-model="textInput"
    :placeholder="placeholder"
    :error-message="errorMessage"
    :disabled="disabled"
    @change="$emit('onInputChange')"
    @focus="handleOnFocus"
    @blur="handleOnBlur"
    @keydown.enter="handlePressEnter"
  >
    <template #leftIcon>
      <slot name="icon" />
    </template>
  </BaseTextInput>

  <VTooltip
    v-if="isShowTooltip"
    :activator="activator"
    max-width="200"
    offset="0 80"
    location="bottom right"
  >
    <div
      class="tooltip"
      v-html="tooltipContent"
    />
  </VTooltip>
</template>
