export enum LOCAL_STORAGE_ITEMS {
	USER_SECRET = 'user_secret',
	ACCESS_TOKEN = 'access_token',
	GOOGLE_ACCESS_TOKEN = 'google_access_token',
	GOOGLE_REFRESH_TOKEN = 'google_refresh_token',
	USER = 'user',
}

export enum SESSION_STORAGE_ITEMS {
	UTM = 'utm',
}
