import { createVuetify } from 'vuetify';
import {
	VMenu,
	VList,
	VListItem,
	VListItemAction,
	VTooltip,
	VTabs,
	VTab,
	VLayout,
	VBottomNavigation,
	VDialog,
	VSnackbar,
	VSwitch,
	VSelect,
	VDataTable,
	VTable,
	VPagination,
	VChip,
	VWindow,
	VWindowItem,
	VProgressCircular,
	VProgressLinear,
	VSkeletonLoader,
	VCarousel,
	VCarouselItem,
	VTimeline,
	VTimelineItem,
	VOverlay,
	VDivider,
	VExpansionPanel,
	VExpansionPanels,
	VExpansionPanelTitle,
	VExpansionPanelText,
} from 'vuetify/components';

import 'vuetify/styles';

export default createVuetify({
	components: {
		VMenu,
		VList,
		VListItem,
		VListItemAction,
		VTooltip,
		VTabs,
		VTab,
		VDialog,
		VLayout,
		VBottomNavigation,
		VSnackbar,
		VSwitch,
		VDataTable,
		VTable,
		VSelect,
		VPagination,
		VChip,
		VWindow,
		VWindowItem,
		VProgressCircular,
		VProgressLinear,
		VSkeletonLoader,
		VCarousel,
		VCarouselItem,
		VTimeline,
		VTimelineItem,
		VOverlay,
		VDivider,
		VExpansionPanel,
		VExpansionPanels,
		VExpansionPanelTitle,
		VExpansionPanelText,
	},
});
