<script setup lang="ts">
const props = defineProps({
	color: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '20',
	},
	height: {
		type: String,
		default: '20',
	},
});
const color = computed(() => props.color);
const { color: iconColor } = useIconColor(color);

</script>
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6668 9.16699H6.66683M8.3335 12.5003H6.66683M13.3335 5.83366H6.66683M16.6668 5.66699V14.3337C16.6668 15.7338 16.6668 16.4339 16.3943 16.9686C16.1547 17.439 15.7722 17.8215 15.3018 18.0612C14.767 18.3337 14.067 18.3337 12.6668 18.3337H7.3335C5.93336 18.3337 5.2333 18.3337 4.69852 18.0612C4.22811 17.8215 3.84566 17.439 3.60598 16.9686C3.3335 16.4339 3.3335 15.7338 3.3335 14.3337V5.66699C3.3335 4.26686 3.3335 3.5668 3.60598 3.03202C3.84566 2.56161 4.22811 2.17916 4.69852 1.93948C5.2333 1.66699 5.93336 1.66699 7.3335 1.66699H12.6668C14.067 1.66699 14.767 1.66699 15.3018 1.93948C15.7722 2.17916 16.1547 2.56161 16.3943 3.03202C16.6668 3.5668 16.6668 4.26686 16.6668 5.66699Z"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
