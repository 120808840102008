import type { AUTH_SERVICE } from '@/enums/auth';

interface BrowserInfo {
	isInSecureBrowser: boolean;
	browserName: string | null;
	browserType: 'native' | 'webview' | 'in-app' | 'unknown';
}

// Extend Window interface for custom properties
declare global {
	interface Window {
		chrome?: any;
		safari?: any;
		AndroidFunction?: any;
		webkit?: {
			messageHandlers?: any;
		};
	}

	interface Navigator {
		standalone?: boolean;
	}
}

export const isIOSUser = (): boolean => {
	const userAgent = window.navigator.userAgent.toLowerCase();
	return userAgent.includes('mobile') && (userAgent.includes('iphone') || userAgent.includes('ipad'));
};

export const isAndroidUser = (): boolean => {
	const userAgent = window.navigator.userAgent.toLowerCase();
	return userAgent.includes('mobile') && userAgent.includes('android');
};

/**
 * Detects if the current browser is an insecure browser (WebView)
 * Uses multiple detection methods to ensure accuracy
 */
export const detectBrowser = (): BrowserInfo => {
	try {
		const ua = navigator?.userAgent?.toLowerCase();
		const isIOS = /iphone|ipod|ipad/.test(ua);
		const isAndroid = /android/.test(ua);
		const isWebView = /wv/.test(ua);
		const isStandalone = window?.navigator?.standalone;
		const isSafari = /safari/.test(ua) && !/chrome/.test(ua);

		// Check for iOS webview
		if (isIOS && !isStandalone && (!isSafari || !/safari\/\d+/.test(ua))) {
			return {
				isInSecureBrowser: true,
				browserName: 'ios webview',
				browserType: 'webview',
			};
		}

		// Check for Android webview
		if (isAndroid && isWebView) {
			return {
				isInSecureBrowser: true,
				browserName: 'android webview',
				browserType: 'webview',
			};
		}

		// Common in-app browser patterns
		const inAppPatterns = {
			instagram: /instagram/,
			facebook: /fbav|fb_iab|fban/,
			twitter: /twitter/,
			linkedin: /linkedin/,
			line: /line/,
			wechat: /micromessenger/,
			whatsapp: /whatsapp/,
			snapchat: /snapchat/,
			pinterest: /pinterest/,
			miui: /miuibrowser/,
			samsung: /samsungbrowser/,
		};

		// Check for in-app browser patterns
		for (const [browser, pattern] of Object.entries(inAppPatterns)) {
			if (pattern.test(ua)) {
				return {
					isInSecureBrowser: true,
					browserName: browser,
					browserType: 'in-app',
				};
			}
		}

		// Native browser detection
		const getNativeBrowserName = (userAgent: string): string => {
			if (/chrome/.test(userAgent)) return 'chrome';
			if (isSafari) return 'safari';
			if (/firefox/.test(userAgent)) return 'firefox';
			return 'edge';
		};

		// Check for secure browsers
		if (/chrome|firefox|edge|safari/.test(ua)) {
			return {
				isInSecureBrowser: false,
				browserName: getNativeBrowserName(ua),
				browserType: 'native',
			};
		}

		return {
			isInSecureBrowser: false,
			browserName: null,
			browserType: 'native',
		};
	} catch (error) {
		console.warn('Browser detection failed:', error);
		// Fail safe: if we can't detect properly, assume it's an insecure browser
		return {
			isInSecureBrowser: true,
			browserName: 'unknown',
			browserType: 'unknown',
		};
	}
};

/**
 * Simple helper that returns just the boolean for insecure browser detection
 */
export const isInSecureBrowser = (): boolean => {
	return detectBrowser().isInSecureBrowser;
};

/**
 * Returns a URL to redirect to a secure browser
 * @param browserInfo - The browser information object
 * @returns A URL to redirect to a secure browser or null if no redirect is possible
 */
export const getSecureBrowserRedirectUrl = (browserInfo: BrowserInfo, service: AUTH_SERVICE): string | null => {
	if (!browserInfo.isInSecureBrowser) {
		return null;
	}

	const currentUrl = window.location.href;
	const redirectUrl = new URL(currentUrl);

	// Append query parameters
	redirectUrl.searchParams.set('signin_type', service);

	// iOS devices - try to open in Safari
	if (isIOSUser()) {
		return `x-safari-${redirectUrl.toString()}`;
	}

	// Android devices - try to open in Chrome
	if (isAndroidUser()) {
		return `googlechrome://${redirectUrl.toString().substring(currentUrl.indexOf('://') + 3)}`;
	}

	// Not a mobile device or unsupported platform
	return null;
};