<script setup lang="ts">
import fileDownload from 'js-file-download';

import type { Contact } from '@/types/contact';

const props = defineProps({
	isShow: {
		type: Boolean,
		default: false,
		required: true,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits<{
	(e: 'onClose'): void
	(e: 'onSubmit', validData: Contact[], invalidData: Contact[]): void
}>();

const notificationStore = useNotificationStore();

// Reactive array to hold the parsed contact objects
const validContacts = ref<Contact[]>([]);
const invalidContacts = ref<Contact[]>([]);
const fileInputRef = ref<HTMLInputElement | null>(null);
const fileName = ref('');
const isUploaded = ref(false);
const isUploadFailed = ref(false);

const isShowable = computed(() => props.isShow);
const isDisabledAddButton = computed(() => {
	return props.disabled || !isUploaded.value;
});
const uploadFileIconColor = computed(() => {
	if (isUploaded.value && isUploadFailed.value) {
		return ICON_COLOR.ERROR;
	}
	return ICON_COLOR.DEFAULT;
});
const uploadCloseIconColor = computed(() => {
	if (isUploaded.value && isUploadFailed.value) {
		return ICON_COLOR.ERROR;
	}
	return ICON_COLOR.PRIMARY;
});

function handleRemoveFile(): void {
	// Reset the file input element's value if it exists.
	if (fileInputRef.value) {
		fileInputRef.value.value = '';
	}
	// Reset all state
	validContacts.value = [];
	fileName.value = '';
	isUploaded.value = false;
}

function handleClose() {
	handleRemoveFile();
	emit('onClose');
}

function handleSubmit() {
	emit('onSubmit', validContacts.value, invalidContacts.value);
}

function downloadTemplate(): void {
	// Define a CSV template with comments and sample data.
	// Comments (lines starting with '#') will be ignored during parsing.
	const csvContent = `Name,Email
    John Doe,john.doe@example.com
    Jane Doe,jane.doe@example.com
    `;

	// Use js-file-download to download the CSV file.
	fileDownload(csvContent, 'Peatix invitation template.csv');
}

function handleUploadError() {
	notificationStore.showErrorNotification(
		'Incorrect format',
		'The file you uploaded is not formatted as a Peatix CSV.',
	);
	isUploadFailed.value = true;
}

function parseCSV(csvText: string): void {
	// Split CSV content into lines, trim each, and filter out empty or comment lines.
	const filteredLines = csvText
		.split('\n')
		.map((line) => line.trim())
		.filter((line) => line !== '' && !line.startsWith('#'));

	if (filteredLines.length < 1) {
		handleUploadError();
		return; // No data available after filtering
	}

	// The first non-comment line is assumed to be the header.
	const headers = filteredLines[0].split(',').map((item) => item.trim().toLowerCase());

	// Find the index of the 'name' and 'email' columns.
	const nameIndex = headers.indexOf('name');
	const emailIndex = headers.indexOf('email');

	// Validate that the required columns exist.
	if (nameIndex === -1 || emailIndex === -1) {
		handleUploadError();
		return;
	}

	// Reset in case this function is called multiple times.
	validContacts.value = [];
	invalidContacts.value = [];

	// Process each data row (skip the header row).
	for (let i = 1; i < filteredLines.length; i++) {
		// Split the row into cells.
		const row = filteredLines[i].split(',').map((item) => item.trim());

		// Only process rows that have at least as many cells as the header.
		if (row.length >= headers.length) {
			// Check that at least one of "name" or "email" is not empty.
			if (row[nameIndex] !== '' || row[emailIndex] !== '') {
				const contact: Contact = {
					name: row[nameIndex],
					email: row[emailIndex],
				};

				// Validate email format.
				if (isValidEmail(contact.email)) {
					validContacts.value.push(contact);
				} else {
					invalidContacts.value.push(contact);
				}
			}
		}
	}
}

function handleFileUpload(event: Event): void {
	isUploadFailed.value = false;
	if (isUploaded.value) {
		console.log('stop uploading');
		return;
	}

	const input = event.target as HTMLInputElement;
	if (!input.files || input.files.length === 0) {
		handleUploadError();
		return;
	}

	const file: File = input.files[0];
	const reader = new FileReader();
	reader.onload = (e: ProgressEvent<FileReader>) => {
		const csvText = e.target?.result;
		if (typeof csvText === 'string') {
			parseCSV(csvText);
		}
	};
	reader.readAsText(file);
	fileName.value = file.name;
	isUploaded.value = true;
}
</script>

<template>
  <BaseDialog
    v-model="isShowable"
    :size="DIALOG_SIZE.SMALL"
    @on-close="handleClose"
    @keyup.enter="handleSubmit"
  >
    <template #header>
      <p class="text-lg text-semibold">
        Import from CSV file
      </p>
    </template>
    <template #content>
      <div class="csv-content-container">
        <div class="csv-step-container">
          <p class="text-semibold">
            Step 1:
          </p>
          <p class="text-center">
            Download and fill out the
            <span
              class="template-link"
              @click="downloadTemplate"
            >Peatix CSV template</span>
            with your contacts.
          </p>
        </div>

        <div class="csv-step-container">
          <p class="text-semibold">
            Step 2:
          </p>
          <p class="text-center">
            Once your template is ready, upload your CSV file.
          </p>
          <template v-if="isUploaded">
            <BaseButton
              :class="['upload-btn', { '-error': isUploadFailed }]"
              variant="outlined"
              color="gray"
              size="sm"
              :disabled="disabled"
            >
              <FileAltIcon
                class="file-icon"
                :color="uploadFileIconColor"
              />
              <span>{{ fileName }}</span>
              <BaseButton
                class="close-btn"
                variant="subtle"
                color="primary"
                icon-only
                :disabled="disabled"
                @click="handleRemoveFile"
              >
                <CloseCircleIcon
                  :color="uploadCloseIconColor"
                  width="20"
                  height="20"
                />
              </BaseButton>
            </BaseButton>
          </template>
          <template v-else>
            <BaseButton
              class="upload-btn"
              variant="outlined"
              color="gray"
              size="sm"
              :disabled="disabled"
              @click="fileInputRef?.click()"
            >
              <UploadIcon />
              Upload CSV file
            </BaseButton>
          </template>
          <input
            ref="fileInputRef"
            type="file"
            accept=".csv"
            hidden
            @change="handleFileUpload"
          >
        </div>
      </div>
    </template>
    <template #footer>
      <div class="btn-container">
        <BaseButton
          class="w-full"
          variant="solid"
          color="primary"
          type="submit"
          :disabled="isDisabledAddButton"
          @click="handleSubmit"
        >
          Add
        </BaseButton>
      </div>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
.csv-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: rem(45);
  margin: rem(55) 0;

  .csv-step-container {
    width: rem(288);
    display: flex;
    flex-direction: column;
    align-items: center;

    .template-link {
      color: colors-get(primary, 700);
      cursor: pointer;
    }

    .upload-btn {
      margin-top: rem(10);

      &.-error {
        border-color: colors-get(error, 300);
        color: colors-get(error, 700);
      }
    }

    .close-btn {
      padding: 0;
    }

    .file-icon {
      min-width: spacings-get(5);
    }
  }
}

.btn-container {
  display: flex;
  gap: spacings-get(3);
  align-items: center;
  justify-content: space-between;
}
</style>