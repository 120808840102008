import type { EventPromoCodeResponse, EventTicketsInventoryResponse } from '@/types/checkout';
import type { ErrorResponse, MessageInfo } from '@/types/common';
import type { InvitationDetailRequest, InvitationDetailResponse, InviteContactRequest, InviteContactResponse, InviteContactStatusResponse } from '@/types/contact';
import type {
	ContentRequest,
	ContentResponse,
	EventCreateRequest,
	EventUpdateRequest,
	EventDetailResponse,
	EventFilesResponse,
	EventCancellationDetailResponse,
	EventDuplicateFormRequest,
	AiCoverImageResponse,
	EventSeriesDuplicateResponse,
	EventSeriesStatusResponse,
	EventSeriesPollingRequest,
} from '@/types/event';

export const postContentGeneration = (payload: ContentRequest) => {
	return useRequest<ContentResponse, ErrorResponse>(CONTENT_GENERATION_ENDPOINT, REQUEST_METHOD.POST, payload);
};

export const postCreateEvent = (payload: EventCreateRequest) => {
	return useRequest<EventDetailResponse, ErrorResponse>(EVENT_ENDPOINT, REQUEST_METHOD.POST, payload);
};

export const getEventById = (eventId: number) => {
	return useRequest<EventDetailResponse, ErrorResponse>(`${EVENT_ENDPOINT}/${eventId}`, REQUEST_METHOD.GET);
};

export const patchUpdateEvent = (eventId: number, payload: Partial<EventUpdateRequest>) => {
	return useRequest<EventDetailResponse, ErrorResponse>(`${EVENT_ENDPOINT}/${eventId}`, REQUEST_METHOD.PATCH, payload);
};

export const postPublishEvent = (eventId: number, payload: Partial<EventUpdateRequest>) => {
	return useRequest<EventDetailResponse, ErrorResponse>(`${EVENT_ENDPOINT}/${eventId}/publishing`, REQUEST_METHOD.POST, payload);
};

export const postDuplicateEventByEventId = (eventId: number, payload: EventDuplicateFormRequest) => {
	return useRequest<EventDetailResponse, ErrorResponse>(`${EVENT_ENDPOINT}/${eventId}/copies`, REQUEST_METHOD.POST, payload);
};

export const postDuplicateEventSeriesByEventId = (eventId: number, payload: EventDuplicateFormRequest) => {
	return useRequest<EventSeriesDuplicateResponse, ErrorResponse>(`${EVENT_ENDPOINT}/${eventId}/series`, REQUEST_METHOD.POST, payload);
};

export const getEventSeriesStatus = (payload: EventSeriesPollingRequest) => {
	const {eventId, eventSeriesId} = payload;
	return useRequest<EventSeriesStatusResponse, ErrorResponse>(`${EVENT_ENDPOINT}/${eventId}/series?eventSeriesId=${eventSeriesId}`, REQUEST_METHOD.GET);
};

export const postDeleteEventByEventId = (eventId: number) => {
	return useRequest<{}, ErrorResponse>(`${EVENT_ENDPOINT}/${eventId}/deletion`, REQUEST_METHOD.POST);
};

export const postTemporarilyCancelEventByEventId = (eventId: number) => {
	return useRequest<EventCancellationDetailResponse, ErrorResponse>(`${EVENT_ENDPOINT}/${eventId}/cancellations`, REQUEST_METHOD.POST);
};

export const deleteCancelEventByEventId = (eventId: number) => {
	return useRequest<EventCancellationDetailResponse, ErrorResponse>(`${EVENT_ENDPOINT}/${eventId}/cancellations`, REQUEST_METHOD.DELETE);
};

export const postConfirmCancelEventByEventId = (eventId: number, eventCancellationId: number) => {
	return useRequest<{}, ErrorResponse>(`${EVENT_ENDPOINT}/${eventId}/cancellations/${eventCancellationId}/confirm`, REQUEST_METHOD.POST);
};

export const postAttachEventFormByEventId = (eventId: number, formId: number) => {
	return useRequest<{}, ErrorResponse>(`${EVENT_ENDPOINT}/${eventId}/form`, REQUEST_METHOD.POST, {
		formId,
	});
};

export const postDetachEventFormByEventId = (eventId: number, formId: number) => {
	return useRequest<{}, ErrorResponse>(`${EVENT_ENDPOINT}/${eventId}/form`, REQUEST_METHOD.DELETE, {
		formId,
	});
};

export const postEventUploadImageAPI = (eventId: number, file: File | Blob) => {
	const formData = new FormData();
	formData.append('coverFile', file || '');

	return useRequest<EventFilesResponse, ErrorResponse>(
		`${EVENT_ENDPOINT}/${eventId}/uploads`,
		REQUEST_METHOD.POST,
		formData,
		{},
		{
			headers: {
				'Content-Type': 'multipart/form-data',
				Accept: 'application/json',
			},
		},
	);
};

export const deleteEventCoverImageAPI = (eventId: number) => {
	return useRequest<{id: string}, ErrorResponse>(`${EVENT_ENDPOINT}/${eventId}/uploads/cover-image`, REQUEST_METHOD.DELETE);
};

export const getTicketsInventoryAPI = (eventId: number) => {
	return useRequest<EventTicketsInventoryResponse, ErrorResponse>(`${EVENT_ENDPOINT}/${eventId}/tickets-inventory`, REQUEST_METHOD.GET);
};

export const getTicketsPromotionsAPI = (eventId: number, promoCode: string) => {
	return useRequest<EventPromoCodeResponse, ErrorResponse>(`${EVENT_ENDPOINT}/${eventId}/promotions`, REQUEST_METHOD.GET, {}, {
		promotionCode: promoCode,
	});
};

export const postMessageToOrganizer = (eventId: number, messageInfo: MessageInfo) => {
	return useRequest<{}, ErrorResponse>(`${EVENT_ENDPOINT}/${eventId}/messaging/hosts`, REQUEST_METHOD.POST, messageInfo);
};

export const postGenerateCoverImageByAI = (eventId: number) => {
	return useRequest<AiCoverImageResponse, ErrorResponse>(`${EVENT_ENDPOINT}/${eventId}/ai-cover-image`, REQUEST_METHOD.POST);
};

export const postInviteContactAPI = (eventId: number, contacts: InviteContactRequest[], message: string) => {
	return useRequest<InviteContactResponse, ErrorResponse>(`${EVENT_ENDPOINT}/${eventId}/invitations`, REQUEST_METHOD.POST, {
		contacts,
		message,
	});
};

export const getInviteContactStatusAPI = (eventId: number, requestId: string) => {
	return useRequest<InviteContactStatusResponse, ErrorResponse>(`${EVENT_ENDPOINT}/${eventId}/invitations`, REQUEST_METHOD.GET, {}, {
		requestId,
	});
};

export const getInvitationDetailAPI = (eventId: number, invitationId: number, invitationParams: InvitationDetailRequest) => {
	return useRequest<InvitationDetailResponse, ErrorResponse>(`${EVENT_ENDPOINT}/${eventId}/invitations/${invitationId}`, REQUEST_METHOD.GET, {}, invitationParams);
};

export const postConfirmInvitationAPI = (eventId: number, invitationId: number, invitationPayload: InvitationDetailRequest) => {
	return useRequest<{}, ErrorResponse>(`${EVENT_ENDPOINT}/${eventId}/invitations/${invitationId}/confirm`, REQUEST_METHOD.POST, invitationPayload);
};