import type { SETTING_TABS as SettingTabs } from '@/enums/setting';

export const mappedSettingTabTitle: Record<SettingTabs, string> = {
	[SETTING_TABS.ACCOUNT_SETTING]: 'Account setting',
	[SETTING_TABS.PAYOUTS]: 'Payouts',
	[SETTING_TABS.SAVED_CARDS]: 'Saved cards',
	[SETTING_TABS.NOTIFICATION_SETTINGS]: 'Notification settings',
	[SETTING_TABS.TICKET_PURCHASE_HISTORY]: 'Ticket purchase history',
	[SETTING_TABS.FOLLOWING]: 'Following',
	[SETTING_TABS.CONTACTS]: 'Contacts',
};