export enum SETTING_TABS {
	ACCOUNT_SETTING = 'account_setting',
	PAYOUTS = 'payouts',
	SAVED_CARDS = 'saved_cards',
	NOTIFICATION_SETTINGS = 'notification_settings',
	TICKET_PURCHASE_HISTORY = 'ticket_purchase_history',
	FOLLOWING = 'following',
	CONTACTS = 'contacts',
}

export enum FOLLOWING_SORTING_METHOD {
	LATEST_FOLLOWED = 'desc(pod_user.created)',
	OLDEST_FOLLOWED = 'asc(pod_user.created)',
	ALPHABETICAL_A2Z = 'asc(pod.name)',
	ALPHABETICAL_Z2A = 'desc(pod.name)',
}

export enum FOLLOWING_TYPE {
	FOLLOWER = 'follower',
}

export enum SAVED_CARD_SORTING_METHOD {
	LATEST_CREATED = 'desc(created)',
}

export enum TICKET_PURCHASE_HISTORY_COLUMN_KEY {
	NAME = 'name',
	DATE = 'orderDate',
	STATUS = 'status',
	AMOUNT = 'amount',
	SALES_ID = 'salesId',
}

export enum TICKET_PURCHASE_HISTORY_SORTING_METHOD {
	DATE = 'sales.created',
	AMOUNT = 'sales.amount_paid',
	STATUS = 'sales.status',
	NAME = 'sales.name',
}

export enum SAVED_CARD_ENUM {
	EXPIRED = 'Expired',
}

export enum TICKET_PURCHASE_HISTORY_STATUS {
	PAID = 'paid',
	REFUND = 'refund',
}

export enum TICKET_ORDER_HISTORY_STATUS {
	WAITING = 'waiting',
	CANCELED = 'canceled',
}