import type { AxiosRequestConfig } from 'axios';
import { AxiosError } from 'axios';

type RequestConfig = Omit<AxiosRequestConfig, 'url' | 'method' | 'data' | 'params'>;

export default async function useRequest<T, TError = unknown>(
	url: string,
	method: string,
	payload = {},
	params = {},
	config: RequestConfig = {},
) {
	const data = ref<T>();
	const errorMessage = ref<string>('');
	const errorCode = ref<number | null>(null);
	const errorMetadata = ref<TError | null>();
	const status = ref<number>();

	try {
		errorMessage.value = '';
		errorMetadata.value = null;
		const res = await apiClient.request({
			url,
			method,
			params,
			data: payload,
			headers: {
				'X-Peatix-App-Token': import.meta.env.VITE_PEATIX_TOKEN, // For identifying the Peasy request
			},
			...config,
		});

		data.value = res.data;
		status.value = res.status;
	} catch (err) {
		if (err instanceof AxiosError) {
			errorMessage.value = err?.response?.data?.error_description?.message || err?.response?.data?.error_description || COMMON_ERROR_MESSAGE.TRY_AGAIN;
			errorCode.value = err?.response?.data?.error_description?.code || err?.response?.data?.error_code || null;
			errorMetadata.value = err?.response?.data?.error_description?.metadata || err?.response?.data?.metadata;
		}
	}

	return {
		data,
		errorMessage,
		errorCode,
		errorMetadata,
		status,
	};
}
