export function useDebouncedFn<T>(value: T, delay = 500) {
	let timeout: ReturnType<typeof setTimeout>;
	const state = ref(value);

	return customRef((track, trigger) => {
		return {
			get() {
				track();

				return state.value;
			},

			set(newValue) {
				clearTimeout(timeout);

				timeout = setTimeout(() => {
					state.value = newValue;
					trigger();
				}, delay);
			},
		};
	});
}
