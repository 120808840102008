<script setup lang="ts">
const props = defineProps({
	color: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '20',
	},
	height: {
		type: String,
		default: '20',
	},
});
const color = computed(() => props.color);
const { color: iconColor } = useIconColor(color);

</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M11.6673 2.39057V5.83268C11.6673 6.29939 11.6673 6.53275 11.7581 6.71101C11.838 6.86781 11.9655 6.99529 12.1223 7.07519C12.3006 7.16602 12.5339 7.16602 13.0007 7.16602H16.4428M16.6673 8.82287V14.8327C16.6673 16.2328 16.6673 16.9329 16.3948 17.4677C16.1552 17.9381 15.7727 18.3205 15.3023 18.5602C14.7675 18.8327 14.0674 18.8327 12.6673 18.8327H7.33398C5.93385 18.8327 5.23379 18.8327 4.69901 18.5602C4.2286 18.3205 3.84615 17.9381 3.60647 17.4677C3.33398 16.9329 3.33398 16.2328 3.33398 14.8327V6.16602C3.33398 4.76588 3.33398 4.06582 3.60647 3.53104C3.84615 3.06063 4.2286 2.67818 4.69901 2.4385C5.23379 2.16602 5.93385 2.16602 7.33398 2.16602H10.0105C10.6219 2.16602 10.9277 2.16602 11.2154 2.23509C11.4705 2.29633 11.7143 2.39734 11.938 2.53442C12.1903 2.68902 12.4065 2.90521 12.8389 3.33759L15.4957 5.99444C15.9281 6.42682 16.1443 6.64301 16.2989 6.8953C16.436 7.11898 16.537 7.36285 16.5982 7.61794C16.6673 7.90566 16.6673 8.21139 16.6673 8.82287Z"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
