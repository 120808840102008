<script setup lang="ts">
import type { CONTACT_FEILD as CONTACT_FEILD_ENUM } from '@/enums/contact';
import type { Contact } from '@/types/contact';

interface ContactErrorStatus {
	name: string
	email: string
}

const props = defineProps({
	data: {
		type: Object as PropType<Contact>,
		default: () => { },
		required: true,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	isShow: {
		type: Boolean,
		default: false,
		required: true,
	},
	errorMessage: {
		type: String,
		default: '',
	},
	errorStatus: {
		type: Object as PropType<ContactErrorStatus>,
		default: () => { },
	},
});

const emit = defineEmits<{
	(e: 'onChange', data: Contact): void
	(e: 'onDelete'): void
}>();

const formData = reactive<Contact>({
	name: '',
	email: '',
});

const isDisabled = computed(() => {
	return props.disabled;
});

function handleFieldChange(event: Event, field: CONTACT_FEILD_ENUM) {
	// Ensuring event.target is an instance of HTMLInputElement
	if (!(event?.target instanceof HTMLInputElement)) {
		return;
	}
	const targetElement = event?.target as HTMLInputElement;
	let value: string | null = targetElement?.value.toString();
	value = targetElement?.value.toString();

	if (field === CONTACT_FEILD.NAME) {
		formData.name = value;
	} else if (field === CONTACT_FEILD.EMAIL) {
		formData.email = value;
	}
	emit('onChange', formData);
}

// Assign props.data to formData initially
onMounted(() => {
	if (props.data) {
		formData.name = props.data.name;
		formData.email = props.data.email;
	}
});

// Watch for changes in the props.formData and update formData
watch(() => props.data, (newData) => {
	formData.name = newData.name;
	formData.email = newData.email;
});

watch(() => props.isShow, (newIsShow) => {
	// Reset state when hide dialog
	if (!newIsShow) {
		// Reset formData to default state
		formData.name = '';
		formData.email = '';
	}
});
</script>

<template>
  <div class="contact-input-container">
    <div class="contact-input">
      <BaseTextInput
        v-model="formData.name"
        class="name-input"
        placeholder="Name"
        :disabled="isDisabled"
        :error-message="errorStatus.name"
        is-show-custom-error
        @input="(e: Event) => handleFieldChange(e, CONTACT_FEILD.NAME)"
      />
      <BaseTextInput
        v-model="formData.email"
        class="email-input"
        placeholder="Email"
        :disabled="isDisabled"
        :error-message="errorStatus.email"
        is-show-custom-error
        @input="(e: Event) => handleFieldChange(e, CONTACT_FEILD.EMAIL)"
      />
      <BaseButton
        class="close-btn"
        variant="subtle"
        color="gray"
        icon-only
        :disabled="disabled"
        @click="emit('onDelete')"
      >
        <CloseIcon
          width="20"
          height="20"
        />
      </BaseButton>
    </div>
    <p
      v-if="!!errorMessage"
      class="error-message"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<style scoped lang="scss">
.contact-input-container {
	display: flex;
	flex-direction: column;

	.contact-input {
		display: grid;
		grid-template-columns: 1fr 1fr rem(44);
		column-gap: spacings-get(3);

		@include media-query-max(mobile) {
			grid-template-columns: 1fr rem(44);
			grid-template-rows: 1fr 1fr;
			gap: rem(6);

			.name-input {
				grid-area: 1 / 1 / 2 / 3;
			}

			.email-input {
				grid-area: 2 / 1 / 3 / 2;
			}

			.close-btn {
				grid-area: 2 / 2 / 3 / 3;
			}
		}
	}
}
</style>