<script setup lang="ts">
import type { CONTACT_IMPORT_METHOD as CONTACT_IMPORT_METHOD_ENUM } from '@/enums/contact';

import FileIcon from '@/components/icons/FileIcon.vue';
// import WhatsAppIcon from '@/components/icons/WhatsAppIcon.vue';
import GoogleIcon from '@/components/icons/GoogleIcon.vue';
import type { Contact } from '@/types/contact';

const importMethod = [
	{
		name: 'CSV file',
		icon: FileIcon,
		value: CONTACT_IMPORT_METHOD.CSV,
	},
	// {
	// 	name: 'WhatsApp',
	// 	icon: WhatsAppIcon,
	// 	value: CONTACT_IMPORT_METHOD.WHATSAPP,
	// },
	{
		name: 'Google',
		icon: GoogleIcon,
		value: CONTACT_IMPORT_METHOD.GOOGLE,
	},
];

const { isMobile } = useWindowResize();
const contactStore = useContactStore();
const authStore = useAuthStore();
const notificationStore = useNotificationStore();
const profileStore = useProfileStore();
const search = useDebouncedFn('', 1000);
const {
	isCreationPolling,
	retieveContactCreationStatus,
} = usePollingContactStatus();

const importContactResultTitle = ref('');
const isShowImportMenu = ref(false);
const isShowManualContactDialog = ref(false);
const isShowCsvContactDialog = ref(false);
const isShowGoogleContactDialog = ref(false);
const isShowWhatsAppContactDialog = ref(false);
const isShowImportContactResultDialog = ref(false);
const isShowDeleteDlg = ref(false);
const deleteContactId = ref<number | null>(null);
const currentPage = ref(contactStore.currentPage ?? 1);
const googleContacts = ref<Contact[]>([]);
const importResultData = ref<Contact[]>([]);

const profileId = computed(() => Number(profileStore.profileId));
const contactData = computed(() => contactStore.contacts);
const isLoading = computed(() => contactStore.isLoading || isCreationPolling.value);
const paginationInfo = computed(() => contactStore.data?.paginationInfo);
const pollingTitle = computed(() => (isCreationPolling.value ? 'Adding contacts' : 'Inviting contacts'));

const headers = computed(() => {
	if (isMobile.value) {
		return [
			{
				title: 'Contacts / Email',
				align: ALIGNMENT.START,
				key: 'contact',
				sortable: false,
			},
			{
				align: ALIGNMENT.CENTER,
				key: 'delete',
				sortable: false,
				width: '72',
			},
		];
	}

	return [
		{
			title: 'Contacts / Email',
			align: ALIGNMENT.START,
			key: 'contact',
			sortable: false,
		},
		{
			align: ALIGNMENT.CENTER,
			key: 'delete',
			sortable: false,
			width: '72',
		},
	];
});

function resetState() {
	importResultData.value = [];
	googleContacts.value = [];
}

async function handleGoogleContactsSuccess(response: google.accounts.oauth2.CodeResponse) {
	if (response.error) {
		return;
	}
	// Exchange code for token(s) that include contacts permission
	const tokenData = await authStore.generateAuthToken(response.code);
	// Store token separately for contacts
	setLocalStorage(LOCAL_STORAGE_ITEMS.GOOGLE_ACCESS_TOKEN, tokenData.data.access_token);
	await Promise.all([
		contactStore.fetchContacts(tokenData.data.access_token),
		contactStore.retrieveContacts({
			itemPerPage: contactStore.data?.paginationInfo.totalItems,
		}),
	]);

	googleContacts.value = contactStore.googleContacts;
	isShowGoogleContactDialog.value = true;
}

const { googleContactsClient } = useSnsConnect({
	callbackGoogleContactsSuccess: handleGoogleContactsSuccess,
});

function handleGoogleMethodClick() {
	if (!googleContactsClient.value) {
		return;
	}
	// Trigger google authentication prompt
	googleContactsClient.value.requestCode();
}

async function handleFetchData() {
	await contactStore.retrieveContacts({
		searchKeyword: search.value,
		currentPage: currentPage.value,
	});
}

function handlePageChange(page: number) {
	currentPage.value = Number(page);
}

async function handleOpenAddContactDialog() {
	isShowManualContactDialog.value = true;
}

async function handleImportContact(method: CONTACT_IMPORT_METHOD_ENUM, data: Contact[]) {
	try {
		isCreationPolling.value = true;
		await contactStore.createContacts(data);

		// If create contacts API does not return request ID or send error message out
		// We will not poll for the status, and show the error message toast
		if (!contactStore.create.requestId || contactStore.errorMessage) {
			notificationStore.showErrorNotification(COMMON_ERROR_MESSAGE.TRY_AGAIN);
			return;
		}

		await retieveContactCreationStatus(contactStore.create.requestId);
		contactStore.resetCreateRequestId();
		handleFetchData();

		if (method === CONTACT_IMPORT_METHOD.MANUAL) {
			resetState();
		} else {
			importResultData.value = [...contactStore.importSucceedContacts, ...contactStore.importFailedContacts];
			isShowImportContactResultDialog.value = true;
		}
	} finally {
		isCreationPolling.value = false;
	}
}

function handleImportFromManual(data: Contact[]) {
	isShowManualContactDialog.value = false;
	handleImportContact(CONTACT_IMPORT_METHOD.MANUAL, data);
}

function handleImportFromCsv(validData: Contact[], invalidData: Contact[]) {
	const combinedData = [...validData, ...invalidData].map((contact) => ({
		name: contact.name,
		email: contact.email,
	}));
	isShowCsvContactDialog.value = false;
	handleImportContact(CONTACT_IMPORT_METHOD.CSV, combinedData);
}


function handleImportFromGoogle() {
	isShowGoogleContactDialog.value = false;
	handleImportContact(CONTACT_IMPORT_METHOD.GOOGLE, googleContacts.value);
}

function handleImport(method: CONTACT_IMPORT_METHOD_ENUM) {
	switch (method) {
		case CONTACT_IMPORT_METHOD.CSV:
			isShowCsvContactDialog.value = true;
			importContactResultTitle.value = CONTACT_IMPORT_RESULT_TITLE.CSV;
			break;
		case CONTACT_IMPORT_METHOD.GOOGLE:
			importContactResultTitle.value = CONTACT_IMPORT_RESULT_TITLE.GOOGLE;
			handleGoogleMethodClick();
			break;
		case CONTACT_IMPORT_METHOD.WHATSAPP:
			importContactResultTitle.value = CONTACT_IMPORT_RESULT_TITLE.WHATSAPP;
			isShowWhatsAppContactDialog.value = true;
			break;
		default:
			break;
	}
}

function handleOnCloseResultDialog() {
	isShowImportContactResultDialog.value = false;
	resetState();
}

async function handleCloseGoogleContactDialog() {
	isShowGoogleContactDialog.value = false;
	await handleFetchData();
	resetState();
}

async function handleDeleteContact() {
	if (!deleteContactId.value) {
		return;
	}
	await contactStore.removeContact(deleteContactId.value);
	await handleFetchData();
	notificationStore.showSuccessNotification('Successfully deleted contact.');
	isShowDeleteDlg.value = false;
	deleteContactId.value = null;
}

function handleOnDelete(id: number) {
	isShowDeleteDlg.value = true;
	deleteContactId.value = id;
}

// Reset the current page when browse the page
onMounted(() => {
	if (profileId.value) {
		currentPage.value = 1;
		handleFetchData();
	}
});

watch(
	() => profileId.value,
	(newProfileId) => {
		if (newProfileId) {
			resetState();
			currentPage.value = 1;
			handleFetchData();
		}
	},
);

// Watch for changes in search keyword and refetch the contacts
watch(
	() => search.value,
	(newSearch) => {
		resetState();
		currentPage.value = 1;
		contactStore.retrieveContacts({
			searchKeyword: newSearch,
		});
	},
);

// Watch for changes in page number and fetch the contacts
watch(
	() => currentPage.value,
	(newPage, oldPage) => {
		if (Number(newPage) === Number(oldPage)) {
			return;
		}
		resetState();
		contactStore.retrieveContacts({
			searchKeyword: search.value,
			currentPage: newPage,
		});
	},
);
</script>

<template>
  <div class="contact-list-content">
    <div class="search-container">
      <template v-if="isLoading && !contactData?.length">
        <ButtonSkeletonLoader
          class="search-input"
          height="44"
        />
        <ButtonSkeletonLoader height="44" />
        <ButtonSkeletonLoader height="44" />
      </template>
      <template v-else>
        <BaseTextInput
          v-model="search"
          class="search-input"
          placeholder="Search contacts"
          :disabled="isLoading"
        >
          <template #leftIcon>
            <SearchIcon />
          </template>
        </BaseTextInput>
        <BaseButton
          variant="outlined"
          color="gray"
          :disabled="isLoading"
          @click="handleOpenAddContactDialog"
        >
          Add contact
        </BaseButton>

        <BaseButton
          id="import-activator"
          class="import-btn"
          variant="outlined"
          color="gray"
          :disabled="isLoading"
          @click="isShowImportMenu = true"
        >
          <FileDownloadIcon />
          <span>Import</span>
        </BaseButton>
        <VMenu
          activator="#import-activator"
          location="bottom"
          width="200"
        >
          <VList>
            <VListItem
              v-for="method in importMethod"
              :key="method.value"
              :value="method.value"
              :ripple="false"
              @click="handleImport(method.value)"
            >
              <VListItemAction class="import-menu-item">
                <component
                  :is="method.icon"
                  class="icon"
                  width="16"
                  height="16"
                />
                <span class="text-sm text-medium">
                  {{ method.name }}
                </span>
              </VListItemAction>
            </VListItem>
          </VList>
        </VMenu>
      </template>
    </div>
    <VDataTable
      density="compact"
      :show-current-page="false"
      :headers="(headers as any) || []"
      :items="contactData || []"
    >
      <template #no-data>
        <ContactListSkeletonLoader
          v-if="isLoading"
        />
        <div
          v-else
          class="table-no-data"
        >
          <SearchIcon
            class="icon"
            width="24"
            height="24"
          />
          <p class="header text-md text-semibold">
            No contacts found
          </p>
          <p class="text-sm text-regular">
            Start adding your contacts by typing their emails or importing from other channels.
          </p>
        </div>
      </template>
      <template #item="{ item }">
        <ContactItem
          :contact="item"
          :is-loading="isLoading"
          hide-checkbox
          @on-remove="handleOnDelete"
        />
      </template>
      <template #bottom>
        <BasePagination
          :page="currentPage"
          :total-pages="paginationInfo?.totalPages"
          :is-loading="isLoading"
          @on-page-change="handlePageChange"
        />
      </template>
    </VDataTable>
    <ImportManualContactDialog
      v-if="isShowManualContactDialog"
      :is-show="isShowManualContactDialog"
      @on-close="isShowManualContactDialog = false"
      @on-submit="handleImportFromManual"
    />
    <ImportCsvContactDialog
      v-if="isShowCsvContactDialog"
      :is-show="isShowCsvContactDialog"
      @on-close="isShowCsvContactDialog = false"
      @on-submit="handleImportFromCsv"
    />
    <ImportContactResultDialog
      v-if="isShowImportContactResultDialog"
      :is-show="isShowImportContactResultDialog"
      :title="importContactResultTitle"
      :data="importResultData"
      @on-close="handleOnCloseResultDialog"
    />
    <ImportGoogleContactDialog
      v-if="isShowGoogleContactDialog"
      :is-show="isShowGoogleContactDialog"
      :data="googleContacts"
      @on-close="handleCloseGoogleContactDialog"
      @on-submit="handleImportFromGoogle"
    />
    <LoadingDialog :is-show="isCreationPolling">
      <template #title>
        <p>{{ pollingTitle }}</p>
      </template>
      <template #content>
        <p>Please do not refresh or leave this page.</p>
      </template>
    </LoadingDialog>
    <ConfirmDialog
      :is-show="isShowDeleteDlg"
      :is-loading="isLoading"
      confirm-button-name="Delete"
      confirm-button-color="error"
      @on-close="isShowDeleteDlg = false"
      @on-confirm="handleDeleteContact"
    >
      <template #header>
        <div class="icon-header-confirm-dlg icon-error">
          <TrashIcon
            width="24"
            height="24"
            :color="ICON_COLOR.ERROR"
          />
        </div>
      </template>
      <template #title>
        Delete contact
      </template>
      <template #content>
        <p class="text-sm text-regular">
          Are you sure you want to delete this contact? This cannot be undone.
        </p>
      </template>
    </ConfirmDialog>
  </div>
</template>

<style lang="scss" scoped>
.contact-list-content {
  :deep(.v-layout > .v-bottom-navigation) {
    z-index: 2 !important;
  }

  .search-container {
    display: grid;
    grid-template-columns: auto minmax(rem(132), max-content) minmax(rem(132), max-content);
    grid-template-rows: 1fr;
    gap: spacings-get(2);
    padding-bottom: spacings-get(8);

    .search-input svg {
      margin-left: rem(10);
    }

    @include media-query-max(tablet) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;

      .search-input {
        grid-area: 1 / 1 / 2 / 3;
      }

      button {
        padding: spacings-get(3);
      }
    }
  }

  .pagination-container {
    margin-top: spacings-get(5);

    button {
      padding: 0;
    }

    .mobile-pagination {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        width: rem(90);
        display: flex;
        justify-content: flex-end;
      }

      .pagination-info span {
        @include fonts-weight(medium);
      }
    }
  }
}
</style>