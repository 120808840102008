<script setup lang="ts">
const stripeConnectStore = useStripeConnectStore();
const notificationStore = useNotificationStore();
const profileStore = useProfileStore();
const route = useRoute();

const { isStripeConnected } = useStripeConnect(true);

const showDisconnectStripeDialog = ref(false);
const showUnableToDisconnectStripeDialog = ref(false);
const isStripeProcessing = ref(false);

const profileId = computed(() => profileStore.profile?.id);
const stripeId = computed(() => stripeConnectStore.stripeAccountId);
const isConnected = computed(() => stripeConnectStore.isStripeAccountEnabled);
const isLoading = computed(() => {
	return stripeConnectStore.isLoading || profileStore.isLoading;
});

const isColumn = computed(() => {
	return !stripeId.value || (isConnected.value && stripeId.value);
});

const requirementData = computed(() => {
	return stripeConnectStore.connect.data?.data[0].requirements;
});

const status = computed(() => {
	if (!stripeId.value) {
		return STRIPE_CONNECT_STATUS.NOT_CONNECT;
	}
	return (isConnected.value) ? STRIPE_CONNECT_STATUS.SUCCEEDED : STRIPE_CONNECT_STATUS.INCOMPLETE;
});

const currentFullURL = computed(() => {
	// Accessing the full path of the current route
	return getFullUrlPath(route.fullPath);
});

const isShowTimeline = computed(() => !isConnected.value && stripeId.value);

async function handleDisconnectWithStripe() {
	if (!profileId.value) {
		return;
	}

	await stripeConnectStore.disconnectStripeAccount(profileId.value);

	showDisconnectStripeDialog.value = false;
	if (stripeConnectStore.connect.errorMessage) {
		showUnableToDisconnectStripeDialog.value = true;
		return;
	}
	stripeConnectStore.connect.data = null;
	await stripeConnectStore.getStripeAccountInfo(profileId.value);
	notificationStore.showSuccessNotification('Successfully disconnected your Stripe account.');
}

async function handleOnboardingProcess() {
	if (!profileId.value) {
		return;
	}

	await stripeConnectStore.initiateStripeOnboardingProcess(profileId.value, {
		action: 'onboarding',
		returnUrl: `${currentFullURL.value}&${CONNECT_STRIPE_STATUS_QUERY_NAME.CONNECT_STATUS}=${STRIPE_CONNECT_STATUS.SUCCEEDED}`,
		refreshUrl: `${currentFullURL.value}&${CONNECT_STRIPE_STATUS_QUERY_NAME.CONNECT_STATUS}=${STRIPE_CONNECT_STATUS.FAILED}`,
	});
}

async function handleConnectWithStripe() {
	isStripeProcessing.value = true;
	// Start onboarding process
	await handleOnboardingProcess();

	// Redirect to the Stripe connect flow if the link is available
	if (stripeConnectStore.stripeOnboardingLink) {
		openLinkInCurrentTab(stripeConnectStore.stripeOnboardingLink);
	}
}

async function handleLoginStripeDashboard() {
	openLink('https://dashboard.stripe.com/');
}
</script>

<template>
  <div class="payout-container">
    <div class="connect-container">
      <h3 class="connect-header text-sm text-semibold">
        Set up or connect your Stripe account to receive your payouts.
      </h3>

      <template v-if="isLoading && !isStripeProcessing">
        <ButtonSkeletonLoader
          width="300"
          height="46"
        />
      </template>
      <template v-else>
        <StripeTimeline
          v-if="isShowTimeline"
          :status="status"
          :requirements="requirementData"
        />

        <div
          :class="['btn-container', {
            '--column': isColumn,
          }]"
        >
          <template v-if="!stripeId">
            <BaseButton
              class="connect-btn"
              variant="solid"
              color="primary"
              size="lg"
              :disabled="isLoading || isStripeProcessing"
              @click="handleConnectWithStripe"
            >
              <BaseProgressCircular
                v-if="isStripeProcessing"
                :size="24"
                indeterminate
              />
              <template v-else>
                <StripeSmallIcon
                  width="20"
                  height="20"
                />
                Connect your Stripe account
              </template>
            </BaseButton>
          </template>

          <template v-if="status === STRIPE_CONNECT_STATUS.INCOMPLETE">
            <BaseButton
              class="verification-btn"
              variant="solid"
              color="primary"
              size="lg"
              :disabled="isLoading || isStripeProcessing"
              @click="handleConnectWithStripe"
            >
              <BaseProgressCircular
                v-if="isStripeProcessing"
                :size="24"
                indeterminate
              />
              <span v-else>Complete verification</span>
            </BaseButton>
            <BaseButton
              class="disconnect-btn"
              variant="outlined"
              color="gray"
              size="lg"
              :disabled="isLoading || isStripeProcessing"
              @click="showDisconnectStripeDialog = true"
            >
              Disconnect your current account
            </BaseButton>
          </template>

          <template v-if="isConnected">
            <div
              class="disconnect-icon-btn text-md text-semibold"
            >
              <StripeSmallIcon
                width="20"
                height="20"
                is-colorful
              />
              <span>Connected Stripe account</span>
              <BaseButton
                class="close"
                variant="subtle"
                color="gray"
                size="sm"
                icon-only
                :disabled="isLoading"
                @click="showDisconnectStripeDialog = true"
              >
                <CloseCircleIcon />
              </BaseButton>
            </div>

            <BaseButton
              class="dashboard-btn"
              variant="link"
              color="primary"
              :size="BtnSize[0]"
              :disabled="isLoading"
              @click="handleLoginStripeDashboard"
            >
              <span class="text-semibold">View your Stripe dashboard</span>
              <LinkExternalIcon :color="isLoading ? ICON_COLOR.DISABLED : ICON_COLOR.PRIMARY" />
            </BaseButton>
          </template>
        </div>
      </template>
    </div>
  </div>
  <ConfirmDialog
    :is-show="showDisconnectStripeDialog"
    :is-loading="isLoading"
    confirm-button-name="Proceed"
    @on-close="showDisconnectStripeDialog = false"
    @on-confirm="handleDisconnectWithStripe"
  >
    <template #header>
      <LinkBrokenIcon
        class="icon-header-confirm-dlg icon-info"
        :color="ICON_COLOR.PRIMARY"
      />
    </template>
    <template #title>
      Disconnect Stripe account
    </template>
    <template #content>
      <p class="text-sm text-regular">
        Do you want to disconnect your Stripe account?
      </p>
    </template>
  </ConfirmDialog>
  <ConfirmDialog
    :is-show="showUnableToDisconnectStripeDialog"
    :is-loading="isLoading"
    confirm-button-name="Ok, I got it"
    hide-cancel-button
    @on-close="showUnableToDisconnectStripeDialog = false"
    @on-confirm="showUnableToDisconnectStripeDialog = false"
  >
    <template #header>
      <AlertCircleIcon
        class="icon-header-confirm-dlg icon-warning"
        :color="ICON_COLOR.WARNING"
      />
    </template>
    <template #title>
      Cannot disconnect Stripe account
    </template>
    <template #content>
      <p class="text-sm text-regular">
        Hosting events with paid tickets requires a Stripe account, from the drafting of the event through to its publishing and payout.
      </p>
    </template>
  </ConfirmDialog>
  <LoadingDialog
    :is-show="isStripeConnected"
  >
    <template #title>
      <p>Attempting to connect with Stripe</p>
    </template>
    <template #content>
      <p>Please do not refresh or leave this page.</p>
    </template>
  </LoadingDialog>
</template>

<style lang="scss" scoped>
.payout-container {
  display: flex;
  flex-direction: column;
  row-gap: spacings-get(8);

  .connect-container {
    display: flex;
    flex-direction: column;
    row-gap: spacings-get(4);

    .connect-header {
      color: colors-get(gray, 700);
    }

    .connect-btn {
      width: rem(289);
    }

    .verification-btn {
      width: rem(205);
    }

    .disconnect-btn {
      width: fit-content;
    }

    .disconnect-icon-btn {
      display: flex;
      align-items: center;
      column-gap: spacings-get(2);
      color: colors-get(gray, 600);
    }

    .dashboard-btn {
      width: fit-content;
      display: flex;
      justify-content: flex-start;
      gap: spacings-get(1);
      padding: 0;
      @include fonts-get(regular, text-sm);
    }

    .btn-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: spacings-get(4);

      &.--column {
        flex-direction: column;
        align-items: flex-start;
      }

      @include media-query-max(mobile) {
        flex-direction: column;

        .disconnect-btn .close {
          width: fit-content;
        }
      }
    }
  }
}
</style>