<script setup lang="ts">
import type { Contact } from '@/types/contact';

import ChevronDownIcon from '@/components/icons/ChevronDownIcon.vue';
import ChevronUpIcon from '@/components/icons/ChevronUpIcon.vue';

const props = defineProps({
	isShow: {
		type: Boolean,
		default: false,
		required: true,
	},
	data: {
		type: Array as PropType<Contact[]>,
		default: () => [],
		required: true,
	},
	title: {
		type: String,
		default: '',
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits<{
	(e: 'onClose'): void
}>();

const panel = ref<string[]>([]);
const isShowable = computed(() => props.isShow);
const validData = computed(() => {
	return [...props.data].filter((contact) => contact.name &&
    contact.email &&
    isValidEmail(contact.email));
});

const invalidData = computed(() => {
	return [...props.data].filter((contact) => !contact.name ||
    !contact.email ||
    !isValidEmail(contact.email));
});

const succeedTitle = computed(() => {
	return `${validData.value.length} new contact${getPluralSuffix(validData.value.length)} added`;
});

const failedTitle = computed(() => {
	return `${invalidData.value.length} contact${getPluralSuffix(invalidData.value.length)} could not be added`;
});

function handleClose() {
	emit('onClose');
}

watch(() => props.isShow, (value) => {
	if (value) {
		const panels = [];
		if (invalidData.value.length) {
			panels.push(CONTACT_IMPORT_RESULT_PANEL.FAILED);
		} else if (validData.value.length) {
			panels.push(CONTACT_IMPORT_RESULT_PANEL.SUCCEED);
		}
		panel.value = panels;
	} else {
		panel.value = [];
	}
}, { immediate: true });
</script>

<template>
  <BaseDialog
    v-model="isShowable"
    :size="DIALOG_SIZE.SMALL"
    @on-close="handleClose"
    @keyup.enter="handleClose"
  >
    <template #header>
      <p class="text-lg text-semibold">
        {{ title || CONTACT_IMPORT_RESULT_TITLE.CSV }}
      </p>
    </template>
    <template #content>
      <div class="import-result-container">
        <VExpansionPanels
          v-if="data.length"
          v-model="panel"
          :ripple="false"
          multiple
        >
          <VExpansionPanel
            v-if="invalidData.length"
            class="failed-panel"
            :value="CONTACT_IMPORT_RESULT_PANEL.FAILED"
            bg-color="#F9FAFB"
            :elevation="0"
            :disabled="!invalidData.length"
          >
            <VExpansionPanelTitle
              class="contact-container-title"
              :collapse-icon="ChevronUpIcon"
              :expand-icon="ChevronDownIcon"
            >
              <CloseIcon :color="ICON_COLOR.ERROR" />
              {{ failedTitle }}
            </VExpansionPanelTitle>
            <VExpansionPanelText class="contact-container">
              <div
                v-for="(contact, index) in invalidData"
                :key="index"
                class="contact-item"
              >
                <p class="text-sm contact-name">
                  {{ contact.name || "-" }}
                </p>
                <p class="text-sm contact-email">
                  {{ contact.email || "-" }}
                </p>
              </div>
            </VExpansionPanelText>
          </VExpansionPanel>
          <VExpansionPanel
            v-if="validData.length"
            class="succeed-panel"
            :value="CONTACT_IMPORT_RESULT_PANEL.SUCCEED"
            bg-color="#F9FAFB"
            :elevation="0"
            :disabled="!validData.length"
          >
            <VExpansionPanelTitle
              class="contact-container-title"
              :collapse-icon="ChevronUpIcon"
              :expand-icon="ChevronDownIcon"
            >
              <CheckIcon :color="ICON_COLOR.SUCCESS" />
              {{ succeedTitle }}
            </VExpansionPanelTitle>
            <VExpansionPanelText class="contact-container">
              <div
                v-for="(contact, index) in validData"
                :key="index"
                class="contact-item"
              >
                <p class="text-sm contact-name">
                  {{ contact.name }}
                </p>
                <p class="text-sm contact-email">
                  {{ contact.email }}
                </p>
              </div>
            </VExpansionPanelText>
          </VExpansionPanel>
        </VExpansionPanels>
        <p
          v-else
          class="text-sm"
        >
          No new contacts to import.
        </p>
      </div>
    </template>
    <template #footer>
      <div class="btn-container">
        <BaseButton
          class="w-full"
          variant="solid"
          color="primary"
          :disabled="disabled"
          @click="handleClose"
        >
          Done
        </BaseButton>
      </div>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
.import-result-container {
  display: flex;
  flex-direction: column;
  height: rem(452);

  :deep(.v-expansion-panel-text__wrapper) {
    display: flex;
    flex-direction: column;
    gap: spacings-get(4);
  }

  :deep(.v-expansion-panel) {
    margin-top: spacings-get(4);
  }

  :deep(.v-expansion-panel:not(:first-child)::after) {
    display: none;
  }

  .failed-panel {
    :deep(.v-expansion-panel-title) {
      background-color: colors-get(error, 100);
    }
  }

  .succeed-panel {
    :deep(.v-expansion-panel-title) {
      background-color: colors-get(success, 100);
    }
  }

  .contact-container-title {
    display: flex;
    gap: rem(6);
    @include fonts-get(medium, text-sm);
    color: colors-get(gray, 900);

    :deep(.v-expansion-panel-title__overlay) {
      opacity: 0 !important;
    }
  }

  .contact-container {
    overflow-y: auto;

    .contact-item {
      display: flex;
      flex-direction: column;
    }

    .contact-name {
      color: colors-get(gray, 900);
    }

    .contact-email {
      color: colors-get(gray, 600);
    }
  }
}

.btn-container {
  display: flex;
  gap: spacings-get(3);
  align-items: center;
  justify-content: space-between;
}
</style>