<script setup lang="ts">
import type { Contact } from '@/types/contact';

const props = defineProps({
	isShow: {
		type: Boolean,
		default: false,
		required: true,
	},
	data: {
		type: Array as PropType<Contact[]>,
		default: () => [],
		required: true,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits<{
	(e: 'onClose'): void
	(e: 'onSubmit'): void
}>();

const isShowable = computed(() => props.isShow);

function handleClose() {
	emit('onClose');
}

function handleSubmit() {
	emit('onSubmit');
}
</script>

<template>
  <BaseDialog
    v-model="isShowable"
    :size="DIALOG_SIZE.SMALL"
    @on-close="handleClose"
    @keyup.enter="handleSubmit"
  >
    <template #header>
      <p class="text-lg text-semibold">
        Import from Google contacts
      </p>
    </template>
    <template #content>
      <div class="import-google-container">
        <template v-if="data.length > 0">
          <p class="text-medium text-sm">
            {{ `${data?.length || 0} new contact${getPluralSuffix(data?.length)} found` }}
          </p>
          <div class="contact-list-container">
            <AvatarLink
              v-for="(contact, index) in data"
              :key="index"
              class="contact-item"
              :image-url="contact?.avatar"
              :alt="contact?.name || `contact avatar`"
              :size="AvatarSizes[0]"
              is-preview-mode
            >
              <template #content>
                <p class="contact-email text-medium">
                  {{ contact?.email }}
                </p>
              </template>
            </AvatarLink>
          </div>
        </template>
        <template v-else>
          <p class="text-sm">
            There are no new contacts to upload from this Google account.
          </p>
        </template>
      </div>
    </template>
    <template #footer>
      <div class="btn-container">
        <BaseButton
          class="w-full"
          variant="solid"
          color="primary"
          :disabled="disabled || !data.length"
          @click="handleSubmit"
        >
          Add
        </BaseButton>
      </div>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
.import-google-container {
  display: flex;
  flex-direction: column;
  height: rem(320);
  gap: spacings-get(4);

  .contact-list-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: spacings-get(4);
    background-color: colors-get(gray, 50);
    @include border-radius-default;
    overflow-y: auto;
  }

  .contact-item {
    padding: spacings-get(2) 0;
    column-gap: spacings-get(4);

    .contact-email {
      color: colors-get(gray, 600);
    }
  }
}

.btn-container {
  display: flex;
  gap: spacings-get(3);
  align-items: center;
  justify-content: space-between;
}
</style>