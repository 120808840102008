import type { EventInfo } from '@/types/event';
import type {
	UserProfileResponse,
	UserEventResponse,
	TicketDetailResponse,
	TicketInfo,
	FormattedUserProfile,
} from '@/types/profile';
import type { TicketReceiptInfo, TicketReceiptResponse } from '@/types/ticket';

export const transformUserProfile = (data: UserProfileResponse): FormattedUserProfile => {
	return {
		...data,
		name: data.nickname,
		email: data.email || '',
		avatarUrl: data.avatar || '',
		avatarLargeUrl: data.avatarLarge || '',
		countryId: data.countryOfResidence?.id,
		timezone: data.timezoneId || '',
		isVerified: !!data.verificationStep,
	};
};

export const transformUserEventAttending = (data: UserEventResponse): EventInfo => {
	const { end, start, coverImage, ...newData } = data;
	const isHappeningNow = calculateHappeningNow(start, end);

	// Format display date time
	const displayDate = formatEventDisplayDate(start.utc, start.timezone);
	const displayTime = formatEventDisplayTime(start.utc, start.timezone);
	const displayTimezone = getTimezoneAbbreviation(start.timezone);
	const status = derivedEventSoldoutStatus(data.status, data.end);

	return {
		...newData,
		status,
		displayDate,
		displayTime,
		displayTimezone,
		isHappeningNow,
		coverImageUrl: coverImage,
	};
};

export const transformUserEventAttendingList = (items: UserEventResponse[]): EventInfo[] => items.map(transformUserEventAttending);

export const transformUserEventHosting = (data: UserEventResponse): EventInfo => {
	const { end, start, coverImage, ...newData } = data;
	const isHappeningNow = calculateHappeningNow(start, end);

	// Format display date time
	const displayDate = formatEventDisplayDate(start.utc, start.timezone);
	const displayTime = formatEventDisplayTime(start.utc, start.timezone);
	const displayTimezone = getTimezoneAbbreviation(start.timezone);
	const status = derivedEventSoldoutStatus(data.status, data.end);

	return {
		...newData,
		status,
		displayDate,
		displayTime,
		displayTimezone,
		isHappeningNow,
		coverImageUrl: coverImage,
	};
};

export const transformUserEventHostingList = (items: UserEventResponse[]): EventInfo[] => items.map(transformUserEventHosting);

export const transformTicket = (data: TicketDetailResponse): TicketInfo => {
	const { end, start } = data.event;

	// Calculate happening now
	const isHappeningNow = calculateHappeningNow(start, end);

	const coordinateArray: String[] | null = data.event.locationSettings.venueGeoCoordinates
		? data.event.locationSettings.venueGeoCoordinates.split(',')
		: null;
	const coordinate = coordinateArray
		? {
			lat: Number(coordinateArray[0]),
			lng: Number(coordinateArray[1]),
		}
		: null;

	return {
		...data,
		startDateTime: start.utc,
		endDateTime: end.utc,
		timezone: start.timezone,
		coordinate,
		isHappeningNow,
		qrcode: data.user?.qrcode,
	};
};

export const transformTicketReceipt = (data: TicketReceiptResponse): TicketReceiptInfo => {
	// Show `0` amount for refunded case
	const newAmount = data.status === TICKET_PURCHASE_HISTORY_STATUS.REFUND ? 0 : data.amount;

	return {
		...data,
		amount: formatCurrency(newAmount, data.currency),
		orderDate: convertDateTimeFromUtcToTimezone(data.orderDate.utc, data.orderDate.timezone),
		issuedDate: convertDateTimeFromUtcToTimezone(data.issuedDate.utc, data.issuedDate.timezone),
	};
};
