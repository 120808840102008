export function usePollingContactStatus(
	timeout: number = 60000, // Default timeout set to 60 seconds
	interval: number = 3000, // Default interval set to 3 seconds
) {
	const intervalId = ref<ReturnType<typeof setTimeout>>();
	const timeoutId = ref<ReturnType<typeof setTimeout>>();
	const resolver = ref();
	const isCreationPolling = ref(false);
	const isInvitationPolling = ref(false);
	const contactStore = useContactStore();

	async function clearPolling() {
		if (intervalId.value) {
			clearInterval(intervalId.value);
		}

		if (timeoutId.value) {
			clearTimeout(timeoutId.value);
		}
		isCreationPolling.value = false;
		isInvitationPolling.value = false;
		contactStore.errorMessage = '';
	}

	async function startContactCreationPolling(id: string) {
		if (contactStore.errorMessage) {
			clearPolling();
			return;
		}
		isCreationPolling.value = true;
		intervalId.value = setInterval(async () => {
			await contactStore.getCreateContactStatus(id);
		}, interval);

		// Set a timeout to stop polling after the specified time
		timeoutId.value = setTimeout(() => {
			clearPolling();
			resolver.value();
		}, timeout);

		// Execute first request after create an interval instance
		await contactStore.getCreateContactStatus(id);
	}

	async function startContactInvitationPolling(eventId: number, requestId: string) {
		if (contactStore.errorMessage) {
			clearPolling();
			return;
		}
		isInvitationPolling.value = true;
		intervalId.value = setInterval(async () => {
			await contactStore.getInviteContactStatus(eventId, requestId);
		}, interval);

		// Set a timeout to stop polling after the specified time
		timeoutId.value = setTimeout(() => {
			clearPolling();
			resolver.value();
		}, timeout);

		// Execute first request after create an interval instance
		await contactStore.getInviteContactStatus(eventId, requestId);
	}

	function retieveContactCreationStatus(id: string) {
		startContactCreationPolling(id);

		return new Promise((resolve) => {
			resolver.value = resolve;
		});
	}

	function retieveContactInvitationStatus(eventId: number, requestId: string) {
		startContactInvitationPolling(eventId, requestId);

		return new Promise((resolve) => {
			resolver.value = resolve;
		});
	}

	watch(
		() => contactStore.create.status,
		(newCreateStatus) => {
			if (newCreateStatus === RESPONSE_STATUS.OK) {
				resolver.value();
				clearPolling();
			}
		},
	);

	watch(
		() => contactStore.invite.status,
		(newInviteStatus) => {
			if (newInviteStatus === RESPONSE_STATUS.OK) {
				resolver.value();
				clearPolling();
			}
		},
	);

	watch(() => contactStore.errorMessage, (errorMessage) => {
		// Stop polling when got error
		if (errorMessage) {
			resolver.value();
			clearPolling();
		}
	});

	return {
		isCreationPolling,
		isInvitationPolling,
		retieveContactCreationStatus,
		retieveContactInvitationStatus,
	};
}