import type {
	AuthErrorResponse,
	AuthMfaResponse,
	AuthResponse,
	EmailValidationResponse,
	MfaRequest,
	MfaVerifyRequest,
	SingInWithMfaTokenRequest,
	SignInWithSnsRequest,
	SingUpWithEmailRequest,
	SnsConnectResponse,
	SnsConnectPayload,
} from '@/types/auth';
import type { ErrorResponse } from '@/types/common';

export const postSignInWithSnsAPI = (payload: SignInWithSnsRequest) => {
	return useRequest<AuthResponse, AuthErrorResponse>(SING_IN_ENDPOINT, REQUEST_METHOD.POST, {
		...payload,
	});
};

export const postSignInWithMfaTokenAPI = (payload: SingInWithMfaTokenRequest) => {
	return useRequest<AuthResponse, AuthErrorResponse>(SING_IN_ENDPOINT, REQUEST_METHOD.POST, {
		...payload,
	});
};

export const postSendVerificationCodeAPI = (payload: MfaRequest) => {
	return useRequest<AuthResponse>(USER_CHALLENGE_ENDPOINT, REQUEST_METHOD.POST, {
		...payload,
	});
};

export const postVerifyCodeAPI = (payload: MfaVerifyRequest, identityToken?: string) => {
	const headers = identityToken ? {
		headers: {
			'X-Peatix-Identity-Validation-Token': identityToken,
		},
	} : {};
	return useRequest<AuthMfaResponse>(USER_VERIFICATION_ENDPOINT, REQUEST_METHOD.POST, {
		...payload,
	}, {}, {...headers});
};

export const getValidateEmailAPI = (email: string) => {
	return useRequest<{ data: EmailValidationResponse }>(ACCOUNT_ENDPOINT, REQUEST_METHOD.GET, {}, {
		email,
	});
};

export const postRegisterWithEmailAPI = (payload: SingUpWithEmailRequest) => {
	return useRequest<AuthMfaResponse>(USER_REGISTRATION_PASSWORD_LESS, REQUEST_METHOD.POST, {
		...payload,
	});
};

export const deleteUserSessionAPI = (userId: number) => {
	return useRequest<{}, ErrorResponse>(`${USERS_ENDPOINT}/${userId}/account/current-session`, REQUEST_METHOD.DELETE);
};

export const getSnsConnectAPI = (userId: number) => {
	return useRequest<SnsConnectResponse>(`${USERS_ENDPOINT}/${userId}${SNS_CONNECT_ENDPOINT}`, REQUEST_METHOD.GET);
};

export const postSnsConnectAPI = (userId: number, payload: SnsConnectPayload) => {
	return useRequest<AuthResponse, AuthErrorResponse>(`${USERS_ENDPOINT}/${userId}${SNS_CONNECT_ENDPOINT}`, REQUEST_METHOD.POST, {
		...payload,
	});
};

export const deleteSnsConnectAPI = (userId: number, connectId: number) => {
	return useRequest<{}, ErrorResponse>(`${USERS_ENDPOINT}/${userId}${SNS_CONNECT_ENDPOINT}/${connectId}`, REQUEST_METHOD.DELETE);
};