<script setup lang="ts">
import type { RadiusType } from '@/types/common';

const props = defineProps({
	width: {
		type: String,
		default: '',
	},
	height: {
		type: String,
		default: '',
	},
	radius: {
		type: String as PropType<RadiusType>,
		default: RADIUS.LG,
	},
	loading: {
		type: Boolean,
		default: false,
	},
});

const slots = defineSlots();
const hasSlotContent = computed(() => !!slots.default);
const classes = computed(() => {
	if (props.radius) {
		return `border-radius-${props.radius}`;
	}
	return '';
});
</script>

<template>
  <template v-if="hasSlotContent">
    <VSkeletonLoader
      class="badge-loader"
      type="button"
      :class="classes"
      :loading="loading"
      :width="width"
      :height="height"
    >
      <slot />
    </VSkeletonLoader>
  </template>
  <template v-else>
    <VSkeletonLoader
      class="badge-loader"
      type="button"
      :class="classes"
      :loading="loading"
      :width="width"
      :height="height"
    />
  </template>
</template>

<style scoped lang="scss">
.badge-loader {
  :deep(.v-skeleton-loader__bone) {
    height: rem(22);
    max-width: inherit;
    margin: 0;
  }
}

.border-radius {
  &-none {
    :deep(.v-skeleton-loader__button) {
      border-radius: 0 !important;
    }
  }

  &-default {
    :deep(.v-skeleton-loader__button) {
      border-radius: rem(8) !important;
    }
  }

  &-lg {
    :deep(.v-skeleton-loader__button) {
      border-radius: rem(12) !important;
    }
  }

  &-xl {
    :deep(.v-skeleton-loader__button) {
      border-radius: rem(16) !important;
    }
  }

  &-full {
    :deep(.v-skeleton-loader__button) {
      border-radius: 50% !important;
    }
  }
}
</style>
