<script setup lang="ts">
import type { AuthForm, AuthenticationType } from '@/types/auth';
import useVuelidate from '@vuelidate/core';
import { email, required, helpers } from '@vuelidate/validators';

const props = defineProps({
	type: {
		type: Number as PropType<AuthenticationType>,
		default: AUTH_FORM.SIGN_IN,
	},
	snsServerErrorMessage: {
		type: String,
		default: null,
	},
	serverErrorMessage: {
		type: String,
		default: null,
	},
	isLoading: {
		type: Boolean,
		default: false,
	},
	isSidebar: {
		type: Boolean,
		default: false,
	},
});

const route = useRoute();

const rules = computed(() => {
	const dynamicRules = {
		username: {},
		email: {
			required: helpers.withMessage('Please enter your email address.', required),
			email: helpers.withMessage('Please enter a valid email address.', email),
		},
	};

	if (props.type === AUTH_FORM.SIGN_UP) {
		return {
			...dynamicRules,
			username: {
				required: helpers.withMessage('Please enter your name.', required),
			},
		};
	}

	return dynamicRules;
});

const emit = defineEmits<{
	(e: 'onSubmitEmail', form: AuthForm): void
	(e: 'onGoogleLoginSuccess', authCode: string): void
	(e: 'onAppleLoginSuccess', data: AppleSignInAPI.SignInResponseI): void
	(e: 'onAppleLoginFailedByPopupBlock'): void
	(e: 'onGoogleLoginFailed'): void
}>();

const form = reactive<AuthForm>({
	username: '',
	email: '',
});

const $externalResults = reactive<{ email: string[]}>({
	email: [],
});

const v$ = useVuelidate(rules, form, { $externalResults });
const internalSnsServerErrorMessage = ref('');

function handleGoogleLoginSuccess(response: { code: string, error: string }) {
	if (response?.error) {
		return;
	}
	emit('onGoogleLoginSuccess', response.code);
}

async function handleAppleLoginSuccess() {
	const data = await AppleID.auth.signIn();
	emit('onAppleLoginSuccess', data);
}

async function handleAppleLoginFailed(response: any) {
	if (response.detail.error === 'popup_blocked_by_browser') {
		emit('onAppleLoginFailedByPopupBlock');
	}
}

const { appleSignIn, googleSignInClient } = useSnsConnect({
	callbackAppleSignInSuccess: handleAppleLoginSuccess,
	callbackAppleSignInFailed: handleAppleLoginFailed,
	callbackGoogleSignInSuccess: handleGoogleLoginSuccess,
});

function handleGoogleLoginClick() {
	internalSnsServerErrorMessage.value = '';

	const browserInfo = detectBrowser();
	if (browserInfo.isInSecureBrowser) {
		const redirectUrl = getSecureBrowserRedirectUrl(browserInfo, AUTH_SERVICE.GOOGLE);

		if (redirectUrl) {
			window.location.href = redirectUrl;
		} else {
			// If detection failed, emit the error event to show the pop-up message
			emit('onGoogleLoginFailed');
		}
		return;
	}

	// Trigger google sign in prompt
	googleSignInClient.value?.requestCode();
}

function handleAppleLoginClick() {
	const browserInfo = detectBrowser();
	if (browserInfo.isInSecureBrowser) {
		const redirectUrl = getSecureBrowserRedirectUrl(browserInfo, AUTH_SERVICE.APPLE);
		if (redirectUrl) {
			window.location.href = redirectUrl;
			return; // Exit early after redirect
		}
	}

	appleSignIn.value?.click();
	internalSnsServerErrorMessage.value = '';
}

function handleSubmit() {
	v$.value.$touch();
	if (v$.value.$error) {
		return;
	}

	emit('onSubmitEmail', form);
}

watch(
	() => props.type,
	() => {
		v$.value.$reset();
		$externalResults.email = [];
		internalSnsServerErrorMessage.value = '';
	},
);

watch(() => [form.email, form.username], ([newEmail, newUsername]) => {
	// Reset error validation if email is changed or empty
	if (!newEmail || (newEmail && v$.value.email.$error)) {
		v$.value.email.$reset();
		$externalResults.email = [];
	}

	// Reset error validation if username is changed or empty
	if (newUsername && v$.value.username.$error) {
		v$.value.username.$reset();
	}
	internalSnsServerErrorMessage.value = '';
});

watch(() => props.serverErrorMessage, (serverErrorMessage) => {
	$externalResults.email = [serverErrorMessage];
});

watch(() => props.snsServerErrorMessage, (snsServerErrorMessage) => {
	internalSnsServerErrorMessage.value = snsServerErrorMessage;
});

onMounted(() => {
	const signinType = route.query?.[AUTH_QUERY_NAME.SIGNIN_TYPE];

	if (!signinType) {
		return;
	}

	// Proceed with the authentication process based on the service
	if (signinType === AUTH_SERVICE.GOOGLE) {
		// Trigger Google sign-in process
		handleGoogleLoginClick();
	} else if (signinType === AUTH_SERVICE.APPLE) {
		// Trigger Apple sign-in process
		handleAppleLoginClick();
	}
});
</script>

<template>
  <div class="auth-page-container">
    <h5
      v-if="!isSidebar"
      class="text-display-sm text-semibold"
    >
      Let's get started
    </h5>
    <div class="auth-container">
      <p
        v-if="internalSnsServerErrorMessage"
        class="error-message"
      >
        {{ internalSnsServerErrorMessage }}
      </p>
      <BaseButton
        variant="outlined"
        color="gray"
        :disabled="isLoading"
        @click="handleGoogleLoginClick"
      >
        <template #leftIcon>
          <GoogleIcon />
        </template>
        Continue with Google
      </BaseButton>
      <BaseButton
        variant="outlined"
        color="gray"
        :disabled="isLoading"
        @click="handleAppleLoginClick"
      >
        <template #leftIcon>
          <AppleIcon />
        </template>
        Continue with Apple
      </BaseButton>
      <div
        id="appleid-signin"
        class="display-none"
      />
    </div>
    <Divider text="Or" />
    <form
      class="form-container"
      @submit.prevent="handleSubmit"
    >
      <BaseTextInput
        v-if="type === AUTH_FORM.SIGN_UP"
        v-model="form.username"
        placeholder="Name"
        :disabled="isLoading"
        :error-message="getValidationErrorMessage(v$.username.$errors)"
      />
      <BaseTextInput
        v-model="form.email"
        placeholder="Email"
        type="email"
        :disabled="isLoading"
        :error-message="getValidationErrorMessage(v$.email.$errors)"
        @keydown.enter="handleSubmit"
      />
      <BaseButton
        variant="solid"
        color="primary"
        size="lg"
        type="submit"
        :disabled="isLoading"
      >
        Continue with email
      </BaseButton>
      <slot name="footer" />
      <p class="text-xs text-regular">
        <span>By using Peatix, you agree to the </span>
        <a
          href="https://peatix.com/us/terms"
          target="_blank"
        >Terms of Service</a>
        <span> (updated: Jun 25, 2024) and </span>
        <a
          href="https://peatix.com/us/privacy-policy"
          target="_blank"
        >Privacy Policy</a>
        <span> (updated: Jun 25, 2024).</span>
      </p>
    </form>
  </div>
</template>