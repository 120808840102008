<script setup lang="ts">
import useVuelidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import type { Contact } from '@/types/contact';

const props = defineProps({
	isShow: {
		type: Boolean,
		default: false,
		required: true,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits<{
	(e: 'onClose'): void
	(e: 'onSubmit', value: Contact[]): void
}>();

const isSubmit = ref(false);

const formData = reactive<{
	contacts: Contact[]
}>({
	contacts: [
		{
			name: '',
			email: '',
		},
	],
});

const isShowable = computed(() => props.isShow);

const rules = computed(() => {
	return {
		contacts: {
			$each: helpers.forEach({
				name: {
					isRequired: helpers.withMessage(
						'Please enter name and email.',
						(value: string) => {
							if (!isSubmit.value) {
								return true;
							}
							return !!value;
						},
					),
				},
				email: {
					isRequired: helpers.withMessage(
						'Please enter name and email.',
						(value: string) => {
							if (!isSubmit.value) {
								return true;
							}
							return !!value;
						},
					),
				},
			}),
		},
	};
});

const v$ = useVuelidate(rules, formData);

function handleContactChange(data: Contact, changeIndex: number) {
	if (!formData.contacts?.length) {
		return;
	}

	formData.contacts = formData.contacts.map((contactItem, index) => {
		if (index === changeIndex) {
			return {
				...contactItem,
				name: data.name,
				email: data.email,
			};
		}
		return contactItem;
	});
}

function handleContactDelete(removeIndex: number) {
	if (!formData.contacts?.length) {
		return;
	}
	const clonedFormData: Contact[] = deepClone(formData.contacts);
	formData.contacts = clonedFormData.filter((_contactItem, index) => {
		return index !== removeIndex;
	});
}

function handleAddContact() {
	v$.value.$reset();
	isSubmit.value = false;

	const newContact = {
		name: '',
		email: '',
	};
	const clonedFormData: Contact[] = deepClone(formData.contacts);
	clonedFormData?.push(newContact);
	formData.contacts = clonedFormData;
}

function handleClose() {
	emit('onClose');
}

function handleSubmit() {
	isSubmit.value = true;
	v$.value.$touch();

	if (v$.value.$error) {
		return;
	}

	const deepCloneForm = deepClone(formData.contacts);
	emit('onSubmit', deepCloneForm);

	isSubmit.value = false;
}

function derivedContactErrorMessage(index: number) {
	const nameErrorMessage = getValidationErrorMessageCollection(v$.value.contacts?.$each.$response.$errors, index, 'name');
	const emailErrorMessage = getValidationErrorMessageCollection(v$.value.contacts?.$each.$response.$errors, index, 'email');

	if (
		nameErrorMessage &&
    emailErrorMessage &&
    (nameErrorMessage === emailErrorMessage)
	) {
		return nameErrorMessage;
	} else if (!nameErrorMessage && emailErrorMessage) {
		return emailErrorMessage;
	} else if (nameErrorMessage || emailErrorMessage) {
		return [nameErrorMessage, emailErrorMessage].join('\n');
	}

	return '';
}

function derivedContactErrorStatus(index: number) {
	const nameErrorMessage = getValidationErrorMessageCollection(v$.value.contacts?.$each.$response.$errors, index, 'name');
	const emailErrorMessage = getValidationErrorMessageCollection(v$.value.contacts?.$each.$response.$errors, index, 'email');
	return {
		name: nameErrorMessage,
		email: emailErrorMessage,
	};
}

watch(() => props.isShow, (newIsShow) => {
	// Reset state when hide dialog
	if (!newIsShow) {
		// Reset formData to default state
		formData.contacts = [];

		v$.value.$reset();
		isSubmit.value = false;
	}
});
</script>

<template>
  <BaseDialog
    v-model="isShowable"
    :size="DIALOG_SIZE.MEDIUM"
    @on-close="handleClose"
    @keyup.enter="handleSubmit"
  >
    <template #header>
      <p class="text-lg text-semibold">
        Enter name and email
      </p>
    </template>
    <template #content>
      <div class="manual-contact-container">
        <div class="contact">
          <div
            v-if="formData.contacts?.length"
            class="contact-list"
          >
            <ContactInputItem
              v-for="(contact, index) in formData.contacts"
              :key="index"
              :data="contact"
              :is-show="isShow"
              :disabled="disabled"
              :error-message="derivedContactErrorMessage(index)"
              :error-status="derivedContactErrorStatus(index)"
              @on-delete="handleContactDelete(index)"
              @on-change="(emittedContact: Contact) => handleContactChange(emittedContact, index)"
            />
          </div>
          <BaseButton
            class="add-btn"
            variant="link"
            color="primary"
            size="sm"
            @click="handleAddContact"
          >
            <PlusCircleIcon
              width="20"
              height="20"
            />
            Add another
          </BaseButton>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="btn-container">
        <BaseButton
          class="w-full"
          variant="solid"
          color="primary"
          type="submit"
          :disabled="disabled"
          @click="handleSubmit"
        >
          Add
        </BaseButton>
      </div>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
.manual-contact-container {
  display: flex;
  flex-direction: column;
  gap: spacings-get(4);
  max-height: rem(400);

  .error-message {
    grid-area: error;
  }

  .contact {
    display: flex;
    flex-direction: column;

    .contact-list {
      display: flex;
      flex-direction: column;
      row-gap: spacings-get(3);
      margin-bottom: spacings-get(4);
      padding: spacings-get(1);
    }

    .add-btn {
      display: flex;
      justify-content: flex-start;
      width: fit-content;
      padding: 0;
      @include fonts-weight(medium);
    }
  }
}

.btn-container {
  display: flex;
  gap: spacings-get(3);
  align-items: center;
  justify-content: space-between;
}
</style>