<script setup lang="ts">
const props = defineProps({
	color: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '20',
	},
	height: {
		type: String,
		default: '20',
	},
	isLarge: {
		type: Boolean,
		default: false,
	},
});

const color = computed(() => props.color);
const { color: iconColor } = useIconColor(color);
</script>
<template>
  <template v-if="isLarge">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      :viewBox="`0 0 ${width} ${height}`"
      fill="none"
    >
      <path
        d="M42.9999 36L29.7143 24M18.2857 24L5.00007 36M4 14L20.3298 25.4309C21.6522 26.3565 22.3134 26.8193 23.0325 26.9986C23.6678 27.157 24.3322 27.157 24.9675 26.9986C25.6866 26.8193 26.3478 26.3565 27.6702 25.4309L44 14M13.6 40H34.4C37.7603 40 39.4405 40 40.7239 39.346C41.8529 38.7708 42.7708 37.8529 43.346 36.7239C44 35.4405 44 33.7603 44 30.4V17.6C44 14.2397 44 12.5595 43.346 11.2761C42.7708 10.1471 41.8529 9.2292 40.7239 8.65396C39.4405 8 37.7603 8 34.4 8H13.6C10.2397 8 8.55953 8 7.27606 8.65396C6.14708 9.2292 5.2292 10.1471 4.65396 11.2761C4 12.5595 4 14.2397 4 17.6V30.4C4 33.7603 4 35.4405 4.65396 36.7239C5.2292 37.8529 6.14708 38.7708 7.27606 39.346C8.55953 40 10.2397 40 13.6 40Z"
        :stroke="iconColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </template>
  <template v-else>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      :viewBox="`0 0 ${width} ${height}`"
      fill="none"
    >
      <path
        d="M1.66602 5.83398L8.47012 10.5969C9.02109 10.9825 9.29658 11.1754 9.59624 11.2501C9.86093 11.3161 10.1378 11.3161 10.4025 11.2501C10.7021 11.1754 10.9776 10.9825 11.5286 10.5969L18.3327 5.83398M5.66602 16.6673H14.3327C15.7328 16.6673 16.4329 16.6673 16.9677 16.3948C17.4381 16.1552 17.8205 15.7727 18.0602 15.3023C18.3327 14.7675 18.3327 14.0674 18.3327 12.6673V7.33398C18.3327 5.93385 18.3327 5.23379 18.0602 4.69901C17.8205 4.2286 17.4381 3.84615 16.9677 3.60647C16.4329 3.33398 15.7328 3.33398 14.3327 3.33398H5.66602C4.26588 3.33398 3.56582 3.33398 3.03104 3.60647C2.56063 3.84615 2.17818 4.2286 1.9385 4.69901C1.66602 5.23379 1.66602 5.93385 1.66602 7.33398V12.6673C1.66602 14.0674 1.66602 14.7675 1.9385 15.3023C2.17818 15.7727 2.56063 16.1552 3.03104 16.3948C3.56582 16.6673 4.26588 16.6673 5.66602 16.6673Z"
        :stroke="iconColor"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </template>
</template>