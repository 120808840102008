interface SnsConnectProps {
	callbackAppleSignInSuccess?: () => Promise<void>
	callbackAppleSignInFailed?: (response: any) => Promise<void>
	callbackGoogleSignInSuccess?: (response: google.accounts.oauth2.CodeResponse) => void
	callbackGoogleContactsSuccess?: (response: google.accounts.oauth2.CodeResponse) => void
}

export function useSnsConnect({
	callbackAppleSignInSuccess,
	callbackAppleSignInFailed,
	callbackGoogleSignInSuccess,
	callbackGoogleContactsSuccess,
}: SnsConnectProps) {
	const appleSignIn = ref<HTMLElement | null>(null);
	const googleSignInClient = ref<google.accounts.oauth2.CodeClient>();
	const googleContactsClient = ref<google.accounts.oauth2.CodeClient>();
	const notificationStore = useNotificationStore();

	const initializeGoogleClient = () => {
		if (typeof google?.accounts?.oauth2 !== 'undefined') {
			googleSignInClient.value = google.accounts.oauth2.initCodeClient({
				client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
				scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/contacts.readonly',
				ux_mode: 'popup',
				callback: callbackGoogleSignInSuccess,
			});
		} else {
			notificationStore.showErrorNotification(COMMON_ERROR_MESSAGE.REFRESH_PAGE);
		}
	};

	const initializeGoogleSignInClient = () => {
		if (typeof google?.accounts?.oauth2 !== 'undefined') {
			googleSignInClient.value = google.accounts.oauth2.initCodeClient({
				client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
				scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
				ux_mode: 'popup',
				callback: callbackGoogleSignInSuccess, // Callback for sign‑in
			});
		} else {
			notificationStore.showErrorNotification(COMMON_ERROR_MESSAGE.REFRESH_PAGE);
		}
	};

	const initializeGoogleContactsClient = () => {
		if (typeof google?.accounts?.oauth2 !== 'undefined') {
			googleContactsClient.value = google.accounts.oauth2.initCodeClient({
				client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
				scope: 'https://www.googleapis.com/auth/contacts.readonly',
				ux_mode: 'popup',
				callback: callbackGoogleContactsSuccess, // Callback for contacts import
			});
		} else {
			notificationStore.showErrorNotification(COMMON_ERROR_MESSAGE.REFRESH_PAGE);
		}
	};

	const initializeAppleSignIn = () => {
		if (typeof AppleID !== 'undefined') {
			// https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple#3235722
			AppleID.auth.init({
				clientId: import.meta.env.VITE_APPLE_CLIENT_ID,
				redirectURI: import.meta.env.VITE_APPLE_REDIRECT_URI,
				scope: 'name email',
				usePopup: true,
			});
		} else {
			notificationStore.showErrorNotification(COMMON_ERROR_MESSAGE.REFRESH_PAGE);
		}
	};

	onMounted(() => {
		// Initialize Google client.
		initializeGoogleSignInClient();
		if (callbackGoogleContactsSuccess) {
			initializeGoogleContactsClient();
		}

		// Get apple sign in button
		appleSignIn.value = document.getElementById('appleid-signin');

		// Initialize Apple
		initializeAppleSignIn();

		// Listen for authorization fail.
		if (callbackAppleSignInFailed) {
			document.addEventListener('AppleIDSignInOnFailure', callbackAppleSignInFailed);
		}

		// Listen for authorization success.
		if (callbackAppleSignInSuccess) {
			document.addEventListener('AppleIDSignInOnSuccess', callbackAppleSignInSuccess);
		}
	});

	onUnmounted(() => {
		if (callbackAppleSignInFailed) {
			document.removeEventListener('AppleIDSignInOnFailure', callbackAppleSignInFailed);
		}
		if (callbackAppleSignInSuccess) {
			document.removeEventListener('AppleIDSignInOnSuccess', callbackAppleSignInSuccess);
		}
	});

	return {
		appleSignIn,
		googleSignInClient,
		googleContactsClient,
	};
}
