import type {
	Contact,
	ContactListResponse,
	CreateContactStatusResponse,
	InvitationDetailRequest,
	InvitationDetailResponse,
	InviteContactRequest,
	InviteContactStatusResponse,
	StatusCount,
} from '@/types/contact';
import type { EventInfo } from '@/types/event';
import axios from 'axios';

interface ContactStore {
	data: ContactListResponse | null
	googleData: gapi.client.people.Person[] | null
	errorMessage: string
	isLoading: boolean
	create: {
		data: CreateContactStatusResponse | null
		requestId: string
		status: number | null
	}
	invite: {
		data: InviteContactStatusResponse | null
		requestId: string
		status: number | null
	}
	invitation: {
		data: InvitationDetailResponse | null
	}
}

const useContactStore = defineStore('contact', {
	state: (): ContactStore => ({
		data: null,
		googleData: null,
		errorMessage: '',
		isLoading: false,
		create: {
			data: null,
			requestId: '',
			status: null,
		},
		invite: {
			data: null,
			requestId: '',
			status: null,
		},
		invitation: {
			data: null,
		},
	}),

	getters: {
		contacts: (state): Contact[] => {
			if (!state.data) {
				return [];
			}
			return state.data.data;
		},

		contactCount: (state): StatusCount | null => {
			if (!state.data) {
				return null;
			}
			return state.data.statusCounts;
		},

		invitationLimit: (): number => {
			return import.meta.env.VITE_ENV === ENVIRONMENT.PRODUCTION ? MAXIMUM_INVITATION_LIMIT_PER_EVENT : 10;
		},

		currentPage: (state): number | null => {
			if (!state.data) {
				return null;
			}
			return Number(state.data.paginationInfo.currentPage);
		},

		importSucceedContacts: (state): Contact[] => {
			if (!state.create.data) {
				return [];
			}
			return state.create.data.success;
		},

		importFailedContacts: (state): Contact[] => {
			if (!state.create.data) {
				return [];
			}
			return state.create.data.failed;
		},

		googleContacts: (state): Contact[] => {
			if (!state.googleData) {
				return [];
			}
			return transformGoogleContacts(state.googleData, state.data?.data);
		},

		invitedEvent: (state): EventInfo | null => {
			if (!state.invitation.data) {
				return null;
			}
			return transformInvitedEventDetail(state.invitation.data.event);
		},
	},

	actions: {
		async retrieveContacts({
			eventId,
			searchKeyword,
			currentPage = 1,
			itemPerPage = PAGINATION_CONFIG.ITEM_PER_PAGE,
		}: {
			eventId?: number
			searchKeyword?: string
			currentPage?: number
			itemPerPage?: number
		}) {
			this.isLoading = true;
			this.errorMessage = '';

			const { data, errorMessage } = await getContactListByUserIdAPI({
				...(eventId ? { eventId } : {}),
				...(searchKeyword ? { name: searchKeyword, email: searchKeyword } : {}),
				currentPage,
				itemPerPage,
			});

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.data = data.value;
			}
			this.isLoading = false;
		},

		async createContacts(contacts: Contact[]) {
			this.isLoading = true;
			this.errorMessage = '';
			this.create.status = null;

			const { data, errorMessage } = await postCreateContactAPI(contacts);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.create.requestId = data.value.requestId;
			}
			this.isLoading = false;
		},

		async getCreateContactStatus(id: string) {
			this.isLoading = true;
			this.errorMessage = '';

			const { data, errorMessage, status } = await getCreateContactStatusAPI(id);

			if (status.value) {
				this.create.status = status.value;
			}

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.create.data = data.value;
			}
			this.isLoading = false;
		},

		resetCreateRequestId() {
			this.create.requestId = '';
		},

		async inviteContacts(eventId: number, contacts: InviteContactRequest[], message: string) {
			this.isLoading = true;
			this.errorMessage = '';
			this.invite.status = null;

			const { data, errorMessage } = await postInviteContactAPI(eventId, contacts, message);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.invite.requestId = data.value.requestId;
			}
			this.isLoading = false;
		},

		async getInviteContactStatus(eventId: number, requestId: string) {
			this.isLoading = true;
			this.errorMessage = '';

			const { data, errorMessage, status } = await getInviteContactStatusAPI(eventId, requestId);

			if (status.value) {
				this.invite.status = status.value;
			}

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.invite.data = data.value;
			}
			this.isLoading = false;
		},

		resetInviteRequestId() {
			this.invite.requestId = '';
		},

		async fetchContacts(accessToken: string) {
			try {
				const { data } = await axios.get<gapi.client.people.people.connections.Response>(
					'https://people.googleapis.com/v1/people/me/connections',
					{
						headers: { Authorization: `Bearer ${accessToken}` },
						params: {
							personFields: 'names,emailAddresses,photos',
							pageSize: 1000, // Adjust based on needs
							sortOrder: 'LAST_MODIFIED_DESCENDING',
						},
					},
				);

				this.googleData = data.connections || [];
			} catch (error: any) {
				this.errorMessage = error.message;
			}
		},

		async fetchContactsWithRefresh(
			accessToken: string,
			refreshToken?: string,
		) {
			try {
				this.fetchContacts(accessToken);
			} catch (error: any) {
				// If the error indicates an expired access token and a refresh token is available...
				if (error.response?.status === RESPONSE_STATUS.UNAUTHORIZED && refreshToken) {
					const authStore = useAuthStore();
					const tokenData = await authStore.refreshAccessToken(refreshToken);
					setLocalStorage(LOCAL_STORAGE_ITEMS.GOOGLE_ACCESS_TOKEN, tokenData.access_token);
					this.fetchContacts(tokenData.access_token);
				}
				throw error;
			}
		},

		async getInvitationDetail(eventId: number, invitationId: number, params: InvitationDetailRequest) {
			this.isLoading = true;
			this.errorMessage = '';

			const { data, errorMessage } = await getInvitationDetailAPI(eventId, invitationId, params);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.invitation.data = data.value;
			}
			this.isLoading = false;
		},

		async confirmInvitaiton(eventId: number, invitationId: number, payload: InvitationDetailRequest) {
			this.isLoading = true;
			this.errorMessage = '';

			const { errorMessage } = await postConfirmInvitationAPI(eventId, invitationId, payload);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}
			this.isLoading = false;
		},

		async removeContact(id: number) {
			this.isLoading = true;
			this.errorMessage = '';

			const { errorMessage } = await deleteContactAPI(id);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}
			this.isLoading = false;
		},

		async unsubscribe(
			email: string,
			token: string,
			organizerId?: number,
		) {
			this.isLoading = true;
			this.errorMessage = '';

			const { errorMessage } = await postUnsubscribeAPI({
				email,
				token,
				...(organizerId && { organizerId }),
			});

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}
			this.isLoading = false;
		},
	},
});

export default useContactStore;
