<script setup lang="ts">
import type { DialogSize } from '@/types/dialog';

const props = defineProps({
	isShow: {
		type: Boolean,
		default: false,
	},
	size: {
		type: String as PropType<DialogSize>,
		default: DIALOG_SIZE.SMALL,
	},
	persistent: {
		type: Boolean,
		default: false,
	},
	hideCloseIcon: {
		type: Boolean,
		default: false,
	},
	isDisabledCloseButton: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits<{
	(e: 'onClose', value: boolean): void
}>();

const dialogWidth = computed(() => {
	switch (props.size) {
		case DIALOG_SIZE.SMALL:
			return 400;
		case DIALOG_SIZE.MEDIUM:
			return 600;
		case DIALOG_SIZE.LARGE:
			return 800;
		default:
			// Fallback case
			return 400;
	}
});

function handleCloseDialog() {
	emit('onClose', false);
}

const isShowValue = computed({
	get: () => {
		return props.isShow;
	},
	set: (isShow) => {
		if (!isShow) {
			emit('onClose', false);
		}
	},
});
</script>

<template>
  <VDialog
    v-model="isShowValue"
    class="dialog-container"
    scrim="#101828"
    :max-width="dialogWidth"
    :persistent="persistent"
  >
    <div class="dialog-header">
      <slot name="header" />
      <BaseButton
        v-if="!hideCloseIcon"
        :disabled="isDisabledCloseButton"
        variant="subtle"
        color="gray"
        size="sm"
        icon-only
        @click="handleCloseDialog"
      >
        <CloseIcon />
      </BaseButton>
    </div>
    <div class="content-container">
      <slot name="content" />
    </div>
    <div class="dialog-footer">
      <slot name="footer" />
    </div>
  </VDialog>
</template>

<style scoped lang="scss">
.dialog-container {
	display: flex;
	flex-direction: column;

	.dialog-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: spacings-get(6) spacings-get(6) spacings-get(5);
		color: colors-get(gray, 900);

		&:empty {
			padding-bottom: 0;
		}

		@include media-query-max(mobile) {
			padding: spacings-get(5) spacings-get(4);
		}
	}

	&.overflow-content-unset .content-container {
		overflow: unset;
	}


	.content-container {
		display: flex;
		flex-direction: column;
		gap: spacings-get(1);
		color: colors-get(gray, 700);
		padding: 0 spacings-get(6) rem(4);
		overflow: auto;

		@include media-query-max(mobile) {
			padding: 0 spacings-get(4);
		}
	}

	.dialog-footer {
		padding: spacings-get(8) spacings-get(6) spacings-get(6);

		&:empty {
			padding-top: 0;
		}

		@include media-query-max(mobile) {
			padding: spacings-get(6) spacings-get(4) spacings-get(5);
		}
	}
}
</style>