<script setup lang="ts">
const props = defineProps({
	color: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '20',
	},
	height: {
		type: String,
		default: '20',
	},
});
const color = computed(() => props.color);
const { color: iconColor } = useIconColor(color);

</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M11.6673 1.89063V5.33274C11.6673 5.79945 11.6673 6.03281 11.7581 6.21107C11.838 6.36787 11.9655 6.49535 12.1223 6.57525C12.3006 6.66608 12.5339 6.66608 13.0007 6.66608H16.4428M7.50065 12.4993L10.0007 14.9993M10.0007 14.9993L12.5007 12.4993M10.0007 14.9993L10.0007 9.99935M11.6673 1.66602H7.33398C5.93385 1.66602 5.23379 1.66602 4.69901 1.9385C4.2286 2.17818 3.84615 2.56063 3.60647 3.03104C3.33398 3.56582 3.33398 4.26588 3.33398 5.66602V14.3327C3.33398 15.7328 3.33398 16.4329 3.60647 16.9677C3.84615 17.4381 4.2286 17.8205 4.69901 18.0602C5.23379 18.3327 5.93385 18.3327 7.33398 18.3327H12.6673C14.0674 18.3327 14.7675 18.3327 15.3023 18.0602C15.7727 17.8205 16.1552 17.4381 16.3948 16.9677C16.6673 16.4329 16.6673 15.7328 16.6673 14.3327V6.66602L11.6673 1.66602Z"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
