export enum AUTH_SERVICE {
	GOOGLE = 'Google',
	APPLE = 'Apple',
	PEATIX = 'Peatix',
}

export enum AUTH_FORM {
	SIGN_IN,
	SIGN_UP,
	PUBLISH_VERIFY,
	ACCOUNT_VERIFY,
}

export enum AUTH_CODE_TYPE {
	SIGN_IN = 'signin',
	ORGANIZER = 'organizer',
	IDENTITY_VALIDATION = 'identity_validation',
}

export enum AUTH_PAGE_ORIGIN {
	EVENT = 'event',
	GROUP = 'group',
	PROFILE = 'profile',
	CREATE = 'create',
	INVITATION = 'invitation',
}