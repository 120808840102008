export enum REQUEST_METHOD {
	POST = 'post',
	GET = 'get',
	PUT = 'put',
	PATCH = 'patch',
	DELETE = 'delete',
}

export enum RESPONSE_STATUS {
	OK = 200,
	CREATED = 201,
	ACCEPTED = 202,
	NO_CONTENT = 204,
	BAD_REQUEST = 400,
	UNAUTHORIZED = 401,
	FORBIDDEN = 403,
	NOT_FOUND = 404,
	INTERNAL_SERVER_ERROR = 500,
}