export enum CONTACT_STATUS {
	UNINVITED = 'uninvited',
	INVITED = 'invited',
	ACCEPTED = 'accepted',
	DECLINED = 'declined',
}

export enum CONTACT_IMPORT_METHOD {
	CSV = 'csv',
	WHATSAPP = 'whatsapp',
	GOOGLE = 'google',
	MANUAL = 'manual',
}

export enum CONTACT_FEILD {
	NAME = 'name',
	EMAIL = 'email',
}

export enum CONTACT_IMPORT_RESULT_TITLE {
	CSV = 'Imported from CSV file',
	GOOGLE = 'Imported from Google',
	WHATSAPP = 'Imported from WhatsApp',
}

export enum CONTACT_IMPORT_RESULT_PANEL {
	SUCCEED = 'succeed',
	FAILED = 'failed',
}
