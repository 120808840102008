<script setup lang="ts">
const { isMobile } = useWindowResize();
const slots = useSlots();

const hasButtonSlot = computed(() => !!slots.button);
</script>

<template>
  <div class="page page-inform">
    <div class="message">
      <slot />
    </div>
    <template
      v-if="hasButtonSlot"
    >
      <slot name="button" />
    </template>
    <RouterLink
      v-else
      :to="{name: ROUTE.INDEX.NAME}"
    >
      <BaseButton

        :size="isMobile ? BtnSize[2] : BtnSize[2]"
      >
        Back to home
      </BaseButton>
    </RouterLink>
  </div>
</template>

<style scoped lang="scss">
.page-inform, .page-inform .message {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-inform {
  gap: spacings-get(12);
  min-height: inherit;
  justify-content: center;
  text-align: center;

  @include media-query-max(mobile) {
    gap: spacings-get(8);
  }

  .message {
    gap: spacings-get(6);

    @include media-query-max(mobile) {
      gap: spacings-get(4);
    }
  }
}
</style>